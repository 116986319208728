import React, { useEffect, useState } from "react";
import PropTypes from "prop-types";
import Button from "@material-ui/core/Button";
import Dialog from "@material-ui/core/Dialog";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import DialogTitle from "@material-ui/core/DialogTitle";
import TraductionComponent from "../components/TraductionComponent";
import Message from "./Message";

export default function TraductionPopup({
  isOpen,
  traductions,
  keyToTranslate,
  onClose,
}) {
  const [_traductions, setTraductions] = useState([]);
  const [message, setMessage] = useState({
    type: "", // success, error, warning or info
    text: "",
  });

  useEffect(() => {
    setTraductions(traductions[keyToTranslate]);
  }, [traductions, keyToTranslate]);

  const handleClose = () => {
    if (onClose) onClose(_traductions);
  };

  const handleTraduction = (index) => {
    setTraductions(prev => {
      prev.splice(index, 1);
      if (prev.length === 0) onClose();
      return prev
    })
    setMessage({
      type: "success",
      text: "Traduction ajoutée avec succès",
    });
  };

  const handleError = (index) => {
    setMessage({
      type: "error",
      text: "Un problème est survenu lors de l'ajout de la traduction",
    });
  };

  return (
    <>
      <Dialog
        open={isOpen}
        maxWidth="sm"
        fullWidth={true}
        onClose={handleClose}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle id="alert-dialog-title">
          Compléter les traductions
        </DialogTitle>
        <DialogContent>
          {_traductions.map((word, index) => (
            <TraductionComponent onAdd={() => handleTraduction(index)} onError={handleError} word={word} />
          ))}
        </DialogContent>
        <DialogActions>
          <Button onClick={handleClose} color="secondary">
            Annuler
          </Button>
        </DialogActions>
      </Dialog>
      <Message
        text={message.text}
        type={message.type}
        onClose={() =>
          setMessage({
            type: "", // success, error, warning or info
            text: "",
          })
        }
      />
    </>
  );
}

TraductionPopup.propTypes = {
  isOpen: PropTypes.bool,
  keyToTranslate: PropTypes.string,
  traductions: PropTypes.arrayOf(PropTypes.object),
  onClose: PropTypes.func,
};
