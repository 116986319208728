import React, { useEffect, useState } from "react";
import {
  Button,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  TableSortLabel,
  TextField,
  Grid,
} from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";
import Message from "../components/Message";
import traductionApi from "../api/traductionApi";
import ConfirmButton from "../components/ConfirmButton";
import Skeleton from "@material-ui/lab/Skeleton";
import blacklistApi from "../api/blacklistApi";

const useStyles = makeStyles((theme) => ({
  paper: {
    padding: theme.spacing(3),
    overflow: "auto",
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
  },
  table: {},
  visuallyHidden: {
    border: 0,
    clip: "rect(0 0 0 0)",
    height: 1,
    margin: -1,
    overflow: "hidden",
    padding: 0,
    position: "absolute",
    top: 20,
    width: 1,
  },
  traductionActions: {
    display: "flex",
    justifyContent: "center",
    gap: "5px",
  },
  addBlacklist: {
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    gap: "15px",
    marginBottom: "20px",
    padding: "0 10px",
  },
  inputAddBlacklist: {
    flex: 1,
  },
  btnAddBlacklist: {
    marginTop: "16px",
    flex: "0.2",
  },
  title: {
    textAlign: "center",
  },
}));

// Affiche toutes les traductions
const BlacklistContainer = ({ setMessage }) => {
  const classes = useStyles();
  const [isLoading, setIsLoading] = useState(false);
  const [pagination, setPagination] = useState(0);
  const [displayLoadMore, setDisplayLoadMore] = useState(true);
  const [order, setOrder] = useState("asc");
  const [orderBy, setOrderBy] = useState("");
  const [blacklist, setBlacklist] = useState();
  const [addBlacklist, setAddBlacklist] = useState("");

  useEffect(() => {
    getBlacklist();
  }, []);

  // Recupères tous les mots/phrases de la blacklist
  const getBlacklist = (reset = false) => {
    setIsLoading(true);
    blacklistApi
      .getBlacklist(reset ? (pagination == 0 ? 1 : pagination) * 10 :  10, reset ? 0 : pagination)
      .then((res) => {
        if(res.data.length < 10) setDisplayLoadMore(false);
        else setDisplayLoadMore(true);

        if (!blacklist) {
           setBlacklist(res.data);
           setPagination(1);
        }
        else setBlacklist((prev) => reset ? res.data : prev.concat(res.data));
        setIsLoading(false);
      })
      .catch(() => {
        setIsLoading(false);
        setMessage({
          type: "error",
          text: "Un problème est survenu lors de la récupération des mot/phrases bannis",
        });
      });
  };

  // Met à jour un mot ou une phrase de la blacklist
  const updateBlacklist = (id, phrase) => {
    setIsLoading(true);
    blacklistApi
      .updateBlacklist(id, { phrase })
      .then(() => {
        setMessage({
          type: "success",
          text: "Le mot/phrase a bien été mise à jour",
        });
        getBlacklist(true);
      })
      .catch(() => {
        setIsLoading(false);
        setMessage({
          type: "error",
          text: "Un problème est survenu lors de la modification du mot ou de la phrase",
        });
      });
  };

  // Supprime un mot/phrase de la blacklist
  const removeBlacklist = (id) => {
    setIsLoading(true);
    blacklistApi
      .removeBlacklist(id)
      .then(() => {
        setMessage({
          type: "success",
          text: "Le mot ou la phrase a bien été supprimé",
        });
        getBlacklist(true);
      })
      .catch(() => {
        setIsLoading(false);
        setMessage({
          type: "error",
          text: "Un problème est survenu lors de la suppression du mot ou de la phrase",
        });
      });
  };

  const onChangeAddBlacklist = (e) => setAddBlacklist(e.target.value);

  // Ajoute un mot ou une phrase à la blacklist
  const onAddBlacklist = () => {
    setIsLoading(true);
    blacklistApi
      .addBlacklist({ phrase: addBlacklist })
      .then(() => {
        getBlacklist(true);
        setMessage({
          type: "success",
          text: "Mot/phrase ajoutée avec succès à la blacklist",
        });
        setAddBlacklist("");
      })
      .catch(() => {
        setIsLoading(false);
        setMessage({
          type: "error",
          text: "Un problème est survenu lors de l'ajout du mot/phrase à la blacklist",
        });
      });
  };

  // Trie les traductions en fonction de la clonne sélectionnées
  const handleRequestSort = (property) => {
    const isAsc = orderBy === property && order === "asc";
    setOrder(isAsc ? "desc" : "asc");
    setOrderBy(property);
    setBlacklist((prev) =>
      prev.sort((a, b) => {
        if (property === "Phrase") {
          return a.word < b.word ? (isAsc ? 1 : -1) : isAsc ? -1 : 1;
        } else return 1;
      })
    );
  };

  const loadMore = () => {
    setPagination((prev) => prev + 1);
    getBlacklist();
  };

  return (
    <Grid item xs={12} md={6} component={Paper}>
      <h2 className={classes.title}>Mot/phrase à bannir</h2>
      <section className={classes.addBlacklist}>
        <TextField
          required
          id="addBlacklist"
          name="addBlacklist"
          label="Mot"
          fullWidth
          autoComplete="addBlacklist"
          value={addBlacklist}
          className={classes.inputAddBlacklist}
          onChange={onChangeAddBlacklist}
        />
        <Button
          variant="contained"
          color="primary"
          onClick={onAddBlacklist}
          className={classes.btnAddBlacklist}
          disabled={isLoading}
        >
          Ajouter
        </Button>
      </section>
      <TableContainer>
        <Table className={classes.table} aria-label="simple table">
          <TableHead>
            <TableRow>
              <TableCell
                align="center"
                sortDirection={orderBy === "Word" ? order : false}
              >
                <TableSortLabel
                  active={orderBy === "Word"}
                  direction={orderBy === "Word" ? order : "asc"}
                  onClick={() => handleRequestSort("Phrase")}
                >
                  {orderBy === "Word" ? (
                    <span className={classes.visuallyHidden}>
                      {order === "desc"
                        ? "sorted descending"
                        : "sorted ascending"}
                    </span>
                  ) : null}
                  Mot
                </TableSortLabel>
              </TableCell>
              <TableCell align="center">Actions</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {blacklist
              ? blacklist.map((_blacklist) => (
                  <TableRow key={_blacklist.id}>
                    <LineBlacklist
                      phrase={_blacklist.phrase}
                      id={_blacklist.id}
                      onUpdate={updateBlacklist}
                      onDelete={removeBlacklist}
                    />
                  </TableRow>
                ))
              : [1, 2, 3, 4, 5, 6, 7, 8, 9, 10].map(() => (
                  <TableRow>
                    <TableCell>
                      <Skeleton variant="rect" height="48px" width="100%" />
                    </TableCell>
                    <TableCell>
                      <Skeleton variant="rect" height="48px" width="100%" />
                    </TableCell>
                  </TableRow>
                ))}
          </TableBody>
        </Table>
        {displayLoadMore && (
          <Button
            variant="contained"
            color="primary"
            onClick={loadMore}
            className={classes.btnAddBlacklist}
            disabled={isLoading}
            fullWidth={true}
          >
            Charger plus de traduction
          </Button>
        )}
      </TableContainer>
    </Grid>
  );
};

const LineBlacklist = ({ phrase, id, onUpdate, onDelete }) => {
  const classes = useStyles();
  const [_phrase, setPhrase] = useState(phrase);
  const [edit, setEdit] = useState(false);

  const onChangePhrase = (e) => setPhrase(e.target.value);

  const onCancel = () => {
    setEdit(false);
    setPhrase(_phrase);
  };
  return (
    <>
      <TableCell align="center">
        {edit ? (
          <TextField
            required
            name="word"
            fullWidth
            autoComplete="word"
            value={_phrase}
            onChange={onChangePhrase}
          />
        ) : (
          <p>{_phrase}</p>
        )}
      </TableCell>
      <TableCell align="center">
        <section className={classes.traductionActions}>
          {!edit && (
            <Button
              variant="contained"
              color="primary"
              onClick={() => setEdit(true)}
            >
              Modifier
            </Button>
          )}
          {edit && (
            <>
              <Button
                variant="contained"
                color="primary"
                onClick={() => {onUpdate(id, _phrase); setEdit(false)}}
              >
                Enregistrer
              </Button>
              <Button variant="contained" color="secondary" onClick={onCancel}>
                Annuler
              </Button>
            </>
          )}
          <ConfirmButton
            title="Êtes-vous certain de vouloir supprimer cette traduction ?"
            content="Cette action sera irrévocable."
            btnText="Supprimer"
            onConfirm={() => onDelete(id)}
          />
        </section>
      </TableCell>
    </>
  );
};

export default BlacklistContainer;
