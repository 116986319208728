import React, { useState } from "react";
import Car from "./Car";
import { useHistory } from "react-router-dom";
import Message from "../components/Message";
import { useCar } from "../contexts/carContext";


// Affiche le formulaire de addCaring
const AddCar = () => {
  let history = useHistory();
  const { getCars, setPrevIndex } = useCar();
  const [message, setMessage] = useState({
    type: "", // success, error, warning or info
    text: "",
  });

  const onCarCreated = () => {
    setPrevIndex(null);
    getCars(true);
    history.push("/cars");
  };

  return (
    <>
      <Car onCreated={onCarCreated} />
      <Message
        text={message.text}
        type={message.type}
        onClose={() =>
          setMessage({
            type: "", // success, error, warning or info
            text: "",
          })
        }
      />
    </>
  );
};

export default AddCar;
