import React, { useEffect, useState } from "react";
import { makeStyles } from "@material-ui/core/styles";
import { useHistory, useParams } from "react-router-dom";
import {
  Button,
  FormControl,
  IconButton,
  InputLabel,
  List,
  ListItem,
  ListItemSecondaryAction,
  ListItemText,
  MenuItem,
  Paper,
  Select,
  TextField,
  Tooltip,
  Typography,
} from "@material-ui/core";
import prospectApi from "../api/prospectApi";
import Message from "../components/Message";
import openPDF from "../utils/openPDF";
import GenerateDevis from "../components/GenerateDevis";
import DriveEtaIcon from "@material-ui/icons/DriveEta";
import PictureAsPdfIcon from "@material-ui/icons/PictureAsPdf";
import LinkOffIcon from "@material-ui/icons/LinkOff";

const useStyles = makeStyles((theme) => ({
  container: {
    display: "grid",
    gridTemplateColumns: "minmax(0, 1fr) minmax(0, 1fr)",
    gridTemplateRows: "1fr",
    gridGap: theme.spacing(3),
    [theme.breakpoints.down("md")]: {
      gridTemplateColumns: "minmax(0, 1fr)",
      gridTemplateRows: "1fr 1fr",
    },
  },
  paper: {
    padding: theme.spacing(3),
    display: "flex",
    flexDirection: "column",
    alignContent: "flex-start",
    flexWrap: "wrap",
    gap: theme.spacing(3),
  },
  prospectAttributes: {
    display: "flex",
    justifyContent: "center",
    flexWrap: "wrap",
    gap: theme.spacing(2),
    "& > *": {
      width: "40%",
      flexGrow: 1,
    },
  },
  submit: {
    margin: theme.spacing(3, 0, 2),
  },
  formControl: {
    marginTop: "16px",
    marginBottom: "8px",
    minWidth: 120,
  },
  table: {
    minWidth: 650,
  },
  prospectActions: {
    width: "100%",
    display: "flex",
    alignItems: "center",
    gap: "5px",
  },
  carProspects: {
    width: "100%",
    flex: 1
  },
}));

// Affiche la fiche d'un prospect
const Propsect = () => {
  const classes = useStyles();
  const history = useHistory();
  const [message, setMessage] = useState({
    type: "", // success, error, warning or info
    text: "",
  });
  let { id } = useParams();
  const [loading, setLoading] = useState(false);
  const [openDevis, setOpenDevis] = useState(false);
  const [firstname, setFirstname] = useState();
  const [lastname, setLastname] = useState();
  const [email, setEmail] = useState();
  const [phone, setPhone] = useState();
  const [note, setNote] = useState();
  const [status, setStatus] = useState();
  const [enable, setEnable] = useState();
  const [cars, setCars] = useState([]);

  useEffect(() => {
    if (id) getProspect(id);
  }, [id]);

  // Récupère les informations liées au prospect
  const getProspect = (id) => {
    setLoading(true);
    prospectApi
      .getProspect(id)
      .then(({ data }) => {
        setFirstname(data.firstname);
        setLastname(data.lastname);
        setEmail(data.email);
        setPhone(data.phone);
        setNote(data.note);
        setStatus(data.status);
        setEnable(data.enable);
        setCars(data.cars);
        setLoading(false);
      })
      .catch(() => {
        setLoading(false);
        setMessage({
          type: "error",
          text: "Echec lors de la mise à jour du prospect",
        });
      });
  };

  const onChangeFirstname = (e) => setFirstname(e.target.value);
  const onChangeLastname = (e) => setLastname(e.target.value);
  const onChangeEmail = (e) => setEmail(e.target.value);
  const onChangePhone = (e) => setPhone(e.target.value);
  const onChangeNote = (e) => setNote(e.target.value);
  const onChangeStatus = (e) => setStatus(e.target.value);
  const onChangeEnable = (e) => setEnable(e.target.value);

  // Modifie les informations d'un prospect ou ajoute un nouveau prospect 
  const onSubmit = () => {
    // Si le prospect existe alors on met à jour ses informations
    if (id) {
      prospectApi
        .updateProspect(id, {
          firstname,
          lastname,
          email,
          phone,
          note,
          status,
          enable,
        })
        .then(() =>
          setMessage({
            type: "success",
            text: "Mise à jour du prospect réussi",
          })
        )
        .catch(() =>
          setMessage({
            type: "error",
            text: "Echec lors de la mise à jour du prospect",
          })
        );
    } 
    else { // si le prospect n'existe pas on le créé
      prospectApi
        .addProspect({
          firstname,
          lastname,
          email,
          phone,
          note,
        })
        .then(({ data: { id } }) => {
          history.push(`/prospects/${id}`);
        })
        .catch(() =>
          setMessage({
            type: "error",
            text: "Erreur lors de l'ajout du prospect",
          })
        );
    }
  };

  // Ouvre le pop pour générer un devis
  const onOpenDevis = () => setOpenDevis(true);
  // Callback lors de la fermeture de la popup pour générer le devis
  const onCloseDevis = (sucess = false) => {
    setOpenDevis(false);
    if (sucess && id) getProspect(id);
  };

  // Supprime la voiture lié au prospect
  const onRemoveCar = carId => {
    let _cars = cars.filter(car => car.id !== carId);
    setCars(_cars);
    prospectApi.updateProspect(id, {cars: _cars})
    .then(() => {
      getProspect(id);
      setMessage({
        isOpen: true,
        type: "success",
        text: "Voiture supprimé avec succès",
      })
    })
    .catch(() => {
      getProspect(id);
      setMessage({
        isOpen: true,
        type: "error",
        text: "Error lors de la suppression du véhicule",
      })
    })
  }

  return loading ? (
    <p>Chargement...</p>
  ) : (
    <div className={classes.container}>
      <Paper className={classes.paper}>
        <section className={classes.prospectAttributes}>
          <TextField
            variant="outlined"
            margin="normal"
            required
            fullWidth
            id="firstname"
            label="Prénom"
            name="firstname"
            autoComplete="firstname"
            autoFocus
            value={firstname}
            onChange={onChangeFirstname}
          />
          <TextField
            variant="outlined"
            margin="normal"
            required
            fullWidth
            id="lastname"
            label="Nom"
            name="lastname"
            autoComplete="lastname"
            autoFocus
            value={lastname}
            onChange={onChangeLastname}
          />
          <TextField
            variant="outlined"
            margin="normal"
            required
            fullWidth
            id="email"
            label="Email"
            name="email"
            autoComplete="email"
            autoFocus
            value={email}
            onChange={onChangeEmail}
          />
          <TextField
            variant="outlined"
            margin="normal"
            required
            fullWidth
            id="phone"
            label="Téléphone"
            name="phone"
            autoComplete="phone"
            autoFocus
            value={phone}
            onChange={onChangePhone}
          />
          {id && (
            <FormControl
              variant="outlined"
              className={classes.formControl}
              fullWidth={true}
            >
              <InputLabel id="prospect_status">Statut</InputLabel>
              <Select
                labelId="prospect_status"
                id="prospect_status"
                value={status}
                onChange={onChangeStatus}
                label="Statut"
              >
                <MenuItem value={"en attente"}>En attente</MenuItem>
                <MenuItem value={"offre à envoyer"}>Offre à envoyer</MenuItem>
                <MenuItem value={"offre envoyée"}>Offre envoyée</MenuItem>
              </Select>
            </FormControl>
          )}
          {id && (
            <FormControl
              variant="outlined"
              className={classes.formControl}
              fullWidth={true}
            >
              <InputLabel id="prospect_status">Activer</InputLabel>
              <Select
                labelId="prospect_enable"
                id="prospect_enable"
                value={enable}
                onChange={onChangeEnable}
                label="enable"
              >
                <MenuItem value={true}>Activer</MenuItem>
                <MenuItem value={false}>Désactiver</MenuItem>
              </Select>
            </FormControl>
          )}
          <TextField
            variant="outlined"
            margin="normal"
            fullWidth
            id="note"
            label="Note"
            name="note"
            autoComplete="note"
            autoFocus
            value={note}
            onChange={onChangeNote}
          />
        </section>
        <section className={classes.prospectActions}>
          <Button
            fullWidth
            variant="contained"
            color="primary"
            onClick={onSubmit}
          >
            {id ? "Enregistrer" : "Ajouter le prosect"}
          </Button>
        </section>
      </Paper>
      {id && cars && (
        <Paper className={classes.paper}>
          <section className={classes.carProspects}>
            <Typography variant="h6" gutterBottom>
              Voiture liées:
            </Typography>
            <div>
              <List>
                {cars &&
                  cars.map((car) => (
                    <ListItem>
                      <ListItemText primary={`${car.brand} ${car.model}`} />
                      <ListItemSecondaryAction>
                        <Tooltip title="Afficher la voiture associée">
                          <IconButton
                            aria-label="prospect"
                            onClick={() => history.push(`/cars/${car.id}`)}
                          >
                            <DriveEtaIcon />
                          </IconButton>
                        </Tooltip>
                        <Tooltip title="Afficher le devis (PDF)">
                          <IconButton
                            aria-label="pdf"
                            onClick={() => openPDF(car.id, id)}
                          >
                            <PictureAsPdfIcon />
                          </IconButton>
                        </Tooltip>
                        <Tooltip title="Dissocier la voiture du prospect">
                          <IconButton aria-label="unlink" onClick={() => onRemoveCar(car.id)}>
                            <LinkOffIcon />
                          </IconButton>
                        </Tooltip>
                      </ListItemSecondaryAction>
                    </ListItem>
                  ))}
              </List>
            </div>
          </section>
          <Button
            variant="contained"
            color="primary"
            onClick={onOpenDevis}
            disabled={id == null ? true : false}
            fullWidth
          >
            Ajouter un véhicule
          </Button>
        </Paper>
      )}
      <Message
        text={message.text}
        type={message.type}
        onClose={() =>
          setMessage({
            type: "", // success, error, warning or info
            text: "",
          })
        }
      />
      {id && (
        <GenerateDevis
          isOpen={openDevis}
          prospectId={id}
          onClose={onCloseDevis}
        />
      )}
    </div>
  );
};

export default Propsect;
