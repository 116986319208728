import React, { useState, createContext, useContext, useEffect } from "react";
import carApi from "../api/carApi";

const CarContext = createContext();

//  Contient toutes les informations sur l'utilisateur connecté
export const CarProvider = ({ children }) => {
  const [isLoading, setIsLoading] = useState(false);
  const [message, setMessage] = useState({
    type: "", // success, error, warning or info
    text: "",
  });
  const [search, setSearch] = useState();
  const [searchCars, setSearchCars] = useState([]);
  const [carsPagingation, setCarsPagination] = useState(0);
  const [disableLoadMore, setDisableLoadMore] = useState(false);
  const [countCars, setCountCars] = useState(0);
  const [countLeboncoin, setCountLeboncoin] = useState(0);
  const [countWordpress, setCountWordpress] = useState(0);
  const [countCaroom, setCountCaroom] = useState(0);
  const [prevIndex, setPrevIndex] = useState();
  const [sortLeboncoin, setSortLeboncoin] = useState();
  const [sortWordpress, setSortWordpress] = useState();
  const [sortInvalid, setSortInvalid] = useState();
  const [sortCaroom, setSortCaroom] = useState();

  // Exectué seulement une fois lorsque que le composant est monté
  useEffect(() => {
    getStatistics();
  }, []);

  // Exectué à chaque fois que le client tape une autre recherche ou que l'on trie par leboncoin
  useEffect(() => {
    const timeoutId = setTimeout(() => getCars(), 1000);
    return () => clearTimeout(timeoutId);
  }, [search, sortLeboncoin, sortWordpress, sortInvalid, sortCaroom]);

  const getStatistics = () => {
    carApi
      .getCount()
      .then((res) => {
        setCountCars(res.data.all);
        setCountLeboncoin(res.data.leboncoin);
        setCountWordpress(res.data.wordpress);
        setCountCaroom(res.data.caroom);
      })
      .catch((err) => {
        setMessage({
          type: "error",
          text: "Impossible de récupérer les informations sur le nombres de véhicule",
        });
      });
  };

  // Récupère la liste des voitures
  const getCars = (refresh = false) => {
    setIsLoading(true);
    if(!refresh) setCarsPagination(0);
    carApi
      .getCars(refresh ? 10 * carsPagingation : 10, 0, refresh ? "" : search, refresh ? null : sortLeboncoin, sortWordpress, sortInvalid, sortCaroom)
      .then((res) => {
        setSearchCars((prev) => {
          return [
            ...res.data.map((car) => {
              car.selected = false;
              return car;
            }),
          ];
        });
        setCarsPagination(carsPagingation + 1);
        setIsLoading(false);
      })
      .catch((err) => {
        setIsLoading(false);
        setMessage({
          type: "error",
          text: "Impossible de charger la liste des véhicules",
        });
      });
  };

  const loadMoreCars = () => {
    setIsLoading(true);
    carApi
      .getCars(10, carsPagingation, search, sortLeboncoin, null, null, sortCaroom)
      .then((res) => {
        if (res.data.length == 0) {
          setDisableLoadMore(true);
          setMessage({
            type: "warning",
            text: "Vous avez atteint la fin de la liste",
          });
        } else {
          setSearchCars((prev) => {
            return [
              ...prev,
              ...res.data.map((car) => {
                car.selected = false;
                return car;
              }),
            ];
          });
          setCarsPagination(carsPagingation + 1);
        }
        setIsLoading(false);
      })
      .catch((err) => {
        setIsLoading(false);
        setMessage({
          type: "error",
          text: "Impossible de charger la liste des véhicules",
        });
      });
  };

  return (
    <CarContext.Provider
      value={{
        isLoading,
        setIsLoading,
        getCars,
        loadMoreCars,
        message,
        setMessage,
        search,
        setSearch,
        searchCars,
        setSearchCars,
        carsPagingation,
        setCarsPagination,
        disableLoadMore,
        setDisableLoadMore,
        countCars,
        setCountCars,
        countLeboncoin,
        setCountLeboncoin,
        countWordpress,
        setCountWordpress,
        countCaroom,
        setCountCaroom,
        prevIndex,
        setPrevIndex,
        sortLeboncoin,
        setSortLeboncoin,
        sortWordpress,
        setSortWordpress,
        sortInvalid,
        setSortInvalid,
        sortCaroom,
        setSortCaroom,
      }}
    >
      {children}
    </CarContext.Provider>
  );
};

export const useCar = () => useContext(CarContext);
