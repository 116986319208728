import axios from "axios";

let baseURL = process.env.NODE_ENV === "production" ?  `https://${window.location.hostname}` : "http://localhost:7000";
const getToken = () => JSON.parse(localStorage.getItem("user")) ? JSON.parse(localStorage.getItem("user")).accessToken : "";

const pdfApi = {
  getPdf: (carId, prospectId) => {
    return axios({
      method: "get",
      baseURL,
      url: `api/pdf/cars/${carId}/prospects/${prospectId}`,
      headers: { Authorization: `Bearer ${getToken()}` }
    });
  },
  postPdf: (carId, prospectId, interlocutor, additionalInformation) => {
    return axios({
      method: "post",
      baseURL,
      data: {...additionalInformation, interlocutor: {...interlocutor}},
      url: `api/pdf/cars/${carId}/prospects/${prospectId}`,
      headers: { Authorization: `Bearer ${getToken()}` }
    });
  },
};

export default pdfApi;

