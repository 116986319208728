import React, { useEffect, useState } from "react";
import PropTypes from "prop-types";
import traductionApi from "../api/traductionApi";
import { makeStyles } from "@material-ui/core";
import { Button, TextField } from "@material-ui/core";

const useStyles = makeStyles((theme) => ({
  addTraduction: {
    display: "grid",
    gridTemplateColumns: "1fr 1fr 0.5fr",
    gridTemplateRows: "auto",
    gap: "15px",
    marginBottom: "20px",
    width: "100%",
  },
  btnAddTraduction: {
    marginTop: "16px",
  },
}));

export default function TraductionComponent({ word,onAdd, onError }) {
  const classes = useStyles();
  const [addWord, setAddWord] = useState("");
  const [addTraduction, setAddTraduction] = useState("");
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    if (word) setAddWord(word);
  }, [word]);

  const onChangeAddWord = (e) => setAddWord(e.target.value);
  const onChangeAddTraduction = (e) => setAddTraduction(e.target.value);

  const onAddTraduction = () => {
    setLoading(true)
    traductionApi
      .addTraduction({ word: addWord, traduction: addTraduction, langue: "de" })
      .then(() => {
        onAdd();
        setLoading(false);
      })
      .catch(() =>{
        onError();
        setLoading(true);
      })
  };

  return (
    <section className={classes.addTraduction}>
      <TextField
        required
        name="addWord"
        label="Mot"
        fullWidth
        autoComplete="addWord"
        value={addWord}
        onChange={onChangeAddWord}
      />
      <TextField
        required
        name="addTraduction"
        label="Traduction"
        fullWidth
        autoComplete="addTraduction"
        value={addTraduction}
        onChange={onChangeAddTraduction}
      />
      <Button
        variant="contained"
        color="primary"
        onClick={onAddTraduction}
        disabled={loading}
        className={classes.btnAddTraduction}
      >
        Ajouter
      </Button>
    </section>
  );
}

TraductionComponent.propTypes = {
  word: PropTypes.string,
  onAdd: PropTypes.func,
  onError: PropTypes.func,
};
