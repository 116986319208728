import React, { useEffect, useState } from "react";
import {
  Button,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  TableSortLabel,
  TextField,
  Grid,
} from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";
import Message from "../components/Message";
import traductionApi from "../api/traductionApi";
import ConfirmButton from "../components/ConfirmButton";
import Skeleton from "@material-ui/lab/Skeleton";
import blacklistApi from "../api/blacklistApi";

const useStyles = makeStyles((theme) => ({
  paper: {
    padding: theme.spacing(3),
    overflow: "auto",
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
  },
  table: {},
  visuallyHidden: {
    border: 0,
    clip: "rect(0 0 0 0)",
    height: 1,
    margin: -1,
    overflow: "hidden",
    padding: 0,
    position: "absolute",
    top: 20,
    width: 1,
  },
  traductionActions: {
    display: "flex",
    justifyContent: "center",
    gap: "5px",
  },
  addTraduction: {
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    gap: "15px",
    marginBottom: "20px",
    padding: "0 10px",
  },
  inputAddTraduction: {
    flex: 1,
  },
  btnAddTraduction: {
    marginTop: "16px",
    flex: "0.2",
  },
  title: {
    textAlign: "center",
  },
}));

// Affiche toutes les traductions
const Traduction = ({ setMessage }) => {
  const classes = useStyles();
  const [isLoading, setIsLoading] = useState(false);
  const [pagination, setPagination] = useState(0);
  const [displayLoadMore, setDisplayLoadMore] = useState(true);
  const [order, setOrder] = useState("asc");
  const [orderBy, setOrderBy] = useState("");
  const [traductions, setTraductions] = useState();
  const [addWord, setAddWord] = useState("");
  const [addTraduction, setAddTraduction] = useState("");
  const [blacklist, setBlacklist] = useState();
  const [addBlacklist, setAddBlacklist] = useState("");

  useEffect(() => {
    getTraductions();
  }, []);

  // Recupères toutes les traductions
  const getTraductions = (reset = false) => {
    setIsLoading(true);
    traductionApi
      .getTraductions(reset ? (pagination == 0 ? 1 : pagination) * 10 : 10, reset ? 0 : pagination)
      .then((res) => {
        if (res.data.length < 10) setDisplayLoadMore(false);
        else setDisplayLoadMore(true);

        if (!traductions) setTraductions(res.data);
        else
          setTraductions((prev) => (reset ? res.data : prev.concat(res.data)));
        setIsLoading(false);
      })
      .catch(() => {
        setIsLoading(false);
        setMessage({
          type: "error",
          text: "Un problème est survenu lors de la récupération des traductions",
        });
      });
  };

  // Met à jour un traduction
  const updateTraduction = (id, word, traduction) => {
    setIsLoading(true);
    traductionApi
      .updateTraduction(id, { word, traduction })
      .then(() => {
        setMessage({
          type: "success",
          text: "Le traduction a bien été mise à jour",
        });
        getTraductions(true);
      })
      .catch(() => {
        setIsLoading(false);
        setMessage({
          type: "error",
          text: "Un problème est survenu lors de la modification de la traduction",
        });
      });
  };

  // Supprime une traduction
  const removeTraduction = (id) => {
    setIsLoading(true);
    traductionApi
      .removeTraduction(id)
      .then(() => {
        setMessage({
          type: "success",
          text: "Le traduction a bien été supprimé",
        });
        getTraductions(true);
      })
      .catch(() => {
        setIsLoading(false);
        setMessage({
          type: "error",
          text: "Un problème est survenu lors de la suppression de la traduction",
        });
      });
  };

  const onChangeAddWord = (e) => setAddWord(e.target.value);
  const onChangeAddTraduction = (e) => setAddTraduction(e.target.value);

  // Ajoute une traduction
  const onAddTraduction = () => {
    setIsLoading(true);
    traductionApi
      .addTraduction({ word: addWord, traduction: addTraduction, langue: "de" })
      .then(() => {
        getTraductions(true);
        setMessage({
          type: "success",
          text: "Traduction ajoutée avec succès",
        });
        setAddWord("");
        setAddTraduction("");
      })
      .catch(() => {
        setIsLoading(false);
        setMessage({
          type: "error",
          text: "Un problème est survenu lors de l'ajout de la traduction",
        });
      });
  };

  // Trie les traductions en fonction de la clonne sélectionnées
  const handleRequestSort = (property) => {
    const isAsc = orderBy === property && order === "asc";
    setOrder(isAsc ? "desc" : "asc");
    setOrderBy(property);
    setTraductions((prev) =>
      prev.sort((a, b) => {
        if (property === "Word") {
          return a.word < b.word ? (isAsc ? 1 : -1) : isAsc ? -1 : 1;
        } else if (property === "Traduction") {
          return a.traduction < b.traduction
            ? isAsc
              ? 1
              : -1
            : isAsc
            ? -1
            : 1;
        } else return 1;
      })
    );
  };

  const loadMore = () => {
    setPagination((prev) => prev + 1);
    getTraductions();
  };

  return (
    <Grid item xs={12} md={6} component={Paper}>
      <h2 className={classes.title}>Mot à traduire</h2>
      <section className={classes.addTraduction}>
        <TextField
          required
          id="addWord"
          name="addWord"
          label="Mot"
          fullWidth
          autoComplete="addWord"
          value={addWord}
          onChange={onChangeAddWord}
          className={classes.inputAddTraduction}
        />
        <TextField
          required
          id="addTraduction"
          name="addTraduction"
          label="Traduction"
          fullWidth
          autoComplete="addTraduction"
          value={addTraduction}
          onChange={onChangeAddTraduction}
          className={classes.inputAddTraduction}
        />
        <Button
          variant="contained"
          color="primary"
          onClick={onAddTraduction}
          className={classes.btnAddTraduction}
          disabled={isLoading}
        >
          Ajouter
        </Button>
      </section>
      <TableContainer>
        <Table className={classes.table} aria-label="simple table">
          <TableHead>
            <TableRow>
              <TableCell
                align="center"
                sortDirection={orderBy === "Word" ? order : false}
              >
                <TableSortLabel
                  active={orderBy === "Word"}
                  direction={orderBy === "Word" ? order : "asc"}
                  onClick={() => handleRequestSort("Word")}
                >
                  {orderBy === "Word" ? (
                    <span className={classes.visuallyHidden}>
                      {order === "desc"
                        ? "sorted descending"
                        : "sorted ascending"}
                    </span>
                  ) : null}
                  Mot
                </TableSortLabel>
              </TableCell>
              <TableCell
                align="center"
                sortDirection={orderBy === "Traduction" ? order : false}
              >
                <TableSortLabel
                  active={orderBy === "Traduction"}
                  direction={orderBy === "Traduction" ? order : "asc"}
                  onClick={() => handleRequestSort("Traduction")}
                >
                  {orderBy === "Traduction" ? (
                    <span className={classes.visuallyHidden}>
                      {order === "desc"
                        ? "sorted descending"
                        : "sorted ascending"}
                    </span>
                  ) : null}
                  Traduction
                </TableSortLabel>
              </TableCell>
              <TableCell align="center">Actions</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {traductions
              ? traductions.map((traduction) => (
                  <TableRow key={traduction.id}>
                    <LineTraduction
                      word={traduction.word}
                      traduction={traduction.traduction}
                      id={traduction.id}
                      onUpdate={updateTraduction}
                      onDelete={removeTraduction}
                    />
                  </TableRow>
                ))
              : [1, 2, 3, 4, 5, 6, 7, 8, 9, 10].map(() => (
                  <TableRow>
                    <TableCell>
                      <Skeleton variant="rect" height="48px" width="100%" />
                    </TableCell>
                    <TableCell>
                      <Skeleton variant="rect" height="48px" width="100%" />
                    </TableCell>
                    <TableCell>
                      <Skeleton variant="rect" height="48px" width="100%" />
                    </TableCell>
                  </TableRow>
                ))}
          </TableBody>
        </Table>
        {displayLoadMore && (
          <Button
            variant="contained"
            color="primary"
            onClick={loadMore}
            className={classes.btnAddTraduction}
            disabled={isLoading}
            fullWidth={true}
          >
            Charger plus de traduction
          </Button>
        )}
      </TableContainer>
    </Grid>
  );
};

const LineTraduction = ({ word, traduction, id, onUpdate, onDelete }) => {
  const classes = useStyles();
  const [_word, setWord] = useState(word);
  const [_traduction, setTraduction] = useState(traduction);
  const [edit, setEdit] = useState(false);

  const onChangeWord = (e) => setWord(e.target.value);
  const onChangeTraduction = (e) => setTraduction(e.target.value);

  const onCancel = () => {
    setEdit(false);
    setWord(word);
    setTraduction(traduction);
  };
  return (
    <>
      <TableCell align="center">
        {edit ? (
          <TextField
            required
            name="word"
            fullWidth
            autoComplete="word"
            value={_word}
            onChange={onChangeWord}
          />
        ) : (
          <p>{_word}</p>
        )}
      </TableCell>
      <TableCell align="center">
        {edit ? (
          <TextField
            required
            name="traduction"
            fullWidth
            autoComplete="traduction"
            value={_traduction}
            onChange={onChangeTraduction}
          />
        ) : (
          <p>{_traduction}</p>
        )}
      </TableCell>
      <TableCell align="center">
        <section className={classes.traductionActions}>
          {!edit && (
            <Button
              variant="contained"
              color="primary"
              onClick={() => setEdit(true)}
            >
              Modifier
            </Button>
          )}
          {edit && (
            <>
              <Button
                variant="contained"
                color="primary"
                onClick={() => {onUpdate(id, _word, _traduction); setEdit(false)}}
              >
                Enregistrer
              </Button>
              <Button variant="contained" color="secondary" onClick={onCancel}>
                Annuler
              </Button>
            </>
          )}
          <ConfirmButton
            title="Êtes-vous certain de vouloir supprimer cette traduction ?"
            content="Cette action sera irrévocable."
            btnText="Supprimer"
            onConfirm={() => onDelete(id)}
          />
        </section>
      </TableCell>
    </>
  );
};

export default Traduction;
