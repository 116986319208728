import axios from "axios";

let baseURL =
  process.env.NODE_ENV === "production"
    ? `https://${window.location.hostname}`
    : "http://localhost:7000";
const getToken = () =>
  JSON.parse(localStorage.getItem("user"))
    ? JSON.parse(localStorage.getItem("user")).accessToken
    : "";

const wordpressApi = {
  addPost: (carId) => {
    return axios({
      method: "post",
      baseURL,
      url: `api/wordpress/posts/cars/${carId}`,
      headers: { Authorization: `Bearer ${getToken()}` },
    });
  },
  disableEnablePost: (carId, isDisable) => {
    return axios({
      method: "put",
      baseURL,
      data: { isDisable },
      url: `api/wordpress/posts/cars/${carId}`,
      headers: { Authorization: `Bearer ${getToken()}` },
    });
  },
};

export default wordpressApi;
