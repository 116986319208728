let categoryCodes = [
  { code: 11100, parent: 11000, name: "Berline" },
  { code: 11120, parent: 11100, name: "Mini Citadine" },
  { code: 11130, parent: 11100, name: "Citadine" },
  { code: 11140, parent: 11100, name: "Compacte" },
  { code: 11170, parent: 11100, name: "Luxe" },
  { code: 11450, parent: 11400, name: "Crossover, SUV" },
  { code: 11540, parent: 11500, name: "Sportive" },
  { code: 11550, parent: 11500, name: "Collection" },
  { code: 11560, parent: 11500, name: "Prestige" },
  { code: 11400, parent: 11000, name: "4 x 4" },
  { code: 11200, parent: 11000, name: "Break" },
  { code: 11520, parent: 11500, name: "Cabriolet" },
  { code: 15100, parent: 15000, name: "Camping car" },
  { code: 11510, parent: 11500, name: "Coupé" },
  { code: 15140, parent: 15100, name: "Fourgon / Van" },
  { code: 12430, parent: 12400, name: "Minibus" },
  { code: 11300, parent: 11000, name: "Monospace" },
  { code: 11440, parent: 11400, name: "Pick-up" },
  { code: 12100, parent: 12000, name: "Utilitaire" },
];

module.exports = categoryCodes;
