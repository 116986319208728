import axios from "axios";

let baseURL = process.env.NODE_ENV === "production" ?  `https://${window.location.hostname}` : "http://localhost:7000";

const getToken = () => JSON.parse(localStorage.getItem("user")) ? JSON.parse(localStorage.getItem("user")).accessToken : "";

const userApi = {
  getUsers: () => {
    return axios({
      method: "get",
      baseURL,
      url: `api/users`,
      headers: { Authorization: `Bearer ${getToken()}` },
    });
  },

  getUser: (id) => {
    return axios({
      method: "get",
      baseURL,
      url: `api/users/${id}`,
      headers: { Authorization: `Bearer ${getToken()}` },
    }); 
  },

  addUser: (user) => {
    return axios({
      method: "post",
      baseURL,
      url: `api/users/`,
      data: user,
      headers: { Authorization: `Bearer ${getToken()}` },
    });
  },

  updateUser: (id, user) => {
    return axios({
      method: "put",
      baseURL,
      url: `api/users/${id}`,
      data: user,
      headers: { Authorization: `Bearer ${getToken()}` },
    });
  },

  removeUser: (id) => {
    return axios({
      method: "delete",
      baseURL,
      url: `api/users/${id}`,
      headers: { Authorization: `Bearer ${getToken()}` },
    });
  },

  whoami: () => {
    return axios({
      method: "get",
      baseURL,
      url: `api/users/me`,
      headers: { Authorization: `Bearer ${getToken()}` },
    });
  },

  signin: (email, password) => {
    return axios({
      method: "post",
      baseURL,
      url: `api/auth/login`,
      data: { email, password },
    });
  },

  createUser: (email, password) => {
    return axios({
      method: "post",
      baseURL,
      url: `api/users`,
      headers: { Authorization: `Bearer ${getToken()}` },
      data: { email, password },
    });
  },
};

export default userApi;
