import React, { useState } from "react";
import { useHistory } from "react-router-dom";
import List from "@material-ui/core/List";
import ListItem from "@material-ui/core/ListItem";
import ListItemIcon from "@material-ui/core/ListItemIcon";
import ListItemText from "@material-ui/core/ListItemText";
import DriveEtaIcon from "@material-ui/icons/DriveEta";
import AddBoxIcon from "@material-ui/icons/AddBox";
import AccountBoxIcon from "@material-ui/icons/AccountBox";
import GTranslateIcon from "@material-ui/icons/GTranslate";
import BackupIcon from "@material-ui/icons/Backup";
import SignalWifi3BarLockIcon from "@material-ui/icons/SignalWifi3BarLock";
import LocalOfferIcon from "@material-ui/icons/LocalOffer";
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
} from "@material-ui/core";
import carApi from "../api/carApi";
import Message from "./Message";
import LinearProgress from "@material-ui/core/LinearProgress";
import scrapApi from "../api/scrapApi";

const Navigation = () => {
  const history = useHistory();
  const [isOpenLeboncoin, setIsOpenLeboncoin] = useState(false);
  const [isLoadingLeboncoin, setIsLoadingLeboncoin] = useState(false);
  const [isOpenScrap, setIsOpenScrap] = useState(false);
  const [isOpenCaroom, setIsOpenCaroom] = useState(false);
  const [isLoadingScrap, setIsLoadingScrap] = useState(false);
  const [isLoadingCaroom, setIsLoadingCaroom] = useState(false);
  const [message, setMessage] = useState({
    type: "", // success, error, warning or info
    text: "",
  });

  const goToCars = () => history.push("/cars");
  const goToScrap = () => history.push("/scrap");
  const goToAddCar = () => history.push("/add/car");
  const goToProspects = () => history.push("/prospects");
  const goToTraductions = () => history.push("/traductions");

  const handleCancelLeboncoin = () => setIsOpenLeboncoin(false);
  const handleOpenLeboncoin = () => setIsOpenLeboncoin(true);
  const handleExportLeboncoin = () => {
    setIsLoadingLeboncoin(true);
    carApi
      .exportXml()
      .then(() => {
        setIsLoadingLeboncoin(false);
        setIsOpenLeboncoin(false);
        setMessage({
          isOpen: true,
          type: "success",
          text: "Annonces leboncoin exportées avec succès",
        });
      })
      .catch(() => {
        setIsLoadingLeboncoin(false);
        setIsOpenLeboncoin(false);
        setMessage({
          isOpen: true,
          type: "error",
          text: "Echec de l'export vers leboncoin",
        });
      });
  };

  const handleCancelScrap = () => setIsOpenScrap(false);
  const handleExportScrap = () => {
    setIsLoadingScrap(true);
    scrapApi
      .restart()
      .then(() => {
        setIsLoadingScrap(false);
        setIsOpenScrap(false);
        setMessage({
          isOpen: true,
          type: "success",
          text: "Le proxy est bien relancé",
        });
      })
      .catch(() => {
        setIsLoadingScrap(false);
        setIsOpenScrap(false);
        setMessage({
          isOpen: true,
          type: "error",
          text: "Le proxy est bien relancé",
        });
      });
  };

  const handleCancelCaroom = () => setIsOpenCaroom(false);
  const handleOpenCaroom = () => setIsOpenCaroom(true);
  const handleExportCaroom = () => {
    setIsLoadingCaroom(true);
    carApi
      .exportCaroom()
      .then(() => {
        setMessage({
          isOpen: true,
          type: "success",
          text: "L'export caroom réussi",
        });

        let baseURL =
          process.env.NODE_ENV === "production"
            ? `https://${window.location.hostname}`
            : "http://localhost:7000";

        let url = `${baseURL}/api/cars/caroom/csv`;
        window.open(url, "_blank");
      })
      .catch(() => {
        setMessage({
          isOpen: true,
          type: "error",
          text: "Echec de l'export caroom",
        });
      })
      .finally(() => {
        setIsLoadingCaroom(false);
        setIsOpenCaroom(false);
      });
  };

  return (
    <>
      <List>
        <ListItem button onClick={goToCars}>
          <ListItemIcon>
            <DriveEtaIcon />
          </ListItemIcon>
          <ListItemText primary="Mes voitures" />
        </ListItem>
        <ListItem button onClick={goToScrap}>
          <ListItemIcon>
            <AddBoxIcon />
          </ListItemIcon>
          <ListItemText primary="Scraper une annonce" />
        </ListItem>
        <ListItem button onClick={goToAddCar}>
          <ListItemIcon>
            <AddBoxIcon />
          </ListItemIcon>
          <ListItemText primary="Ajouter une voiture" />
        </ListItem>
        <ListItem button onClick={goToProspects}>
          <ListItemIcon>
            <AccountBoxIcon />
          </ListItemIcon>
          <ListItemText primary="Prospects" />
        </ListItem>
        <ListItem button onClick={goToTraductions}>
          <ListItemIcon>
            <GTranslateIcon />
          </ListItemIcon>
          <ListItemText primary="Traductions" />
        </ListItem>
        <ListItem button onClick={handleOpenLeboncoin}>
          <ListItemIcon>
            <BackupIcon />
          </ListItemIcon>
          <ListItemText primary="Leboncoin" />
        </ListItem>
        <ListItem button onClick={handleOpenCaroom}>
          <ListItemIcon>
            <LocalOfferIcon />
          </ListItemIcon>
          <ListItemText primary="Caroom" />
        </ListItem>
      </List>

      <Dialog
        open={isOpenLeboncoin}
        onClose={handleCancelLeboncoin}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        {isLoadingLeboncoin && <LinearProgress />}
        <DialogTitle id="alert-dialog-title">
          Voulez-vous vraiment exporter les annonces vers leboncoin
        </DialogTitle>
        <DialogContent>
          <DialogContentText id="alert-dialog-description">
            Seulement les annonces avec l'export vers leboncoin d'activé seront
            exportées
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleCancelLeboncoin} color="secondary" autoFocus>
            Annuler
          </Button>
          <Button onClick={handleExportLeboncoin} color="primary">
            Exporter
          </Button>
        </DialogActions>
      </Dialog>

      <Dialog
        open={isOpenScrap}
        onClose={handleCancelScrap}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        {isLoadingScrap && <LinearProgress />}
        <DialogTitle id="alert-dialog-title">
          Voulez-vous vraiment redémarrer le proxy
        </DialogTitle>
        <DialogContent>
          <DialogContentText id="alert-dialog-description">
            Il faudra environ 3-4 minutes pour redémarrer le proxy
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button
            onClick={handleCancelScrap}
            color="secondary"
            autoFocus
            disabled={isLoadingScrap}
          >
            Annuler
          </Button>
          <Button
            onClick={handleExportScrap}
            color="primary"
            disabled={isLoadingScrap}
          >
            Redémarrer
          </Button>
        </DialogActions>
      </Dialog>

      <Dialog
        open={isOpenCaroom}
        onClose={handleCancelCaroom}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        {isLoadingCaroom && <LinearProgress />}
        <DialogTitle id="alert-dialog-title">
          Voulez-vous vraiment exporter les annonces vers caroom
        </DialogTitle>
        <DialogContent>
          <DialogContentText id="alert-dialog-description">
            Ca prendera quelques secondes.
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button
            onClick={handleCancelCaroom}
            color="secondary"
            autoFocus
            disabled={isLoadingCaroom}
          >
            Annuler
          </Button>
          <Button
            onClick={handleExportCaroom}
            color="primary"
            disabled={isLoadingCaroom}
          >
            Exporter
          </Button>
        </DialogActions>
      </Dialog>

      <Message
        text={message.text}
        type={message.type}
        onClose={() =>
          setMessage({
            type: "", // success, error, warning or info
            text: "",
          })
        }
      />
    </>
  );
};

export default Navigation;
