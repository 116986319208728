import React, { useEffect, useState } from "react";
import { useHistory } from "react-router-dom";
import prospectApi from "../api/prospectApi";
import {
  Button,
  Grid,
  Paper,
  TableSortLabel,
  TextField,
  Typography,
  FormGroup,
  FormControlLabel,
  Switch,
} from "@material-ui/core";
import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import TableContainer from "@material-ui/core/TableContainer";
import TableHead from "@material-ui/core/TableHead";
import TableRow from "@material-ui/core/TableRow";
import Message from "../components/Message";
import PersonAddIcon from "@material-ui/icons/PersonAdd";
import { makeStyles } from "@material-ui/core/styles";
import ConfirmButton from "../components/ConfirmButton";
import Skeleton from "@material-ui/lab/Skeleton";

const useStyles = makeStyles((theme) => ({
  container: {
    marginBottom: "40px",
  },
  filtre: {
    padding: theme.spacing(2),
    display: "flex",
    alignItems: "baseline",
    gap: "10px"
  },
  table: {
    minWidth: 650,
  },
  prospectActions: {
    display: "flex",
    justifyContent: "center",
    gap: "5px",
  },
  addProposect: {
    marginLeft: "10px",
  },
  visuallyHidden: {
    border: 0,
    clip: "rect(0 0 0 0)",
    height: 1,
    margin: -1,
    overflow: "hidden",
    padding: 0,
    position: "absolute",
    top: 20,
    width: 1,
  },
}));

// Affiche la liste des prospets
const Prospects = () => {
  const history = useHistory();
  const classes = useStyles();
  const [prospects, setProspects] = useState();
  const [isLoading, setIsLoading] = useState(false);
  const [pagination, setPagination] = useState(0);
  const [search, setSearch] = useState();
  const [enableProspect, setEnableProspect] = useState(true);
  const [displayLoadMore, setDisplayLoadMore] = useState(true);
  const [message, setMessage] = useState({
    type: "", // success, error, warning or info
    text: "",
  });

  const [order, setOrder] = useState("asc");
  const [orderBy, setOrderBy] = useState("");

  useEffect(() => {
    const timeoutId = setTimeout(() => getProspects(true), 1000);
    return () => clearTimeout(timeoutId);
  }, [search, enableProspect]);

  // Récupère la liste des prospects
  const getProspects = (reset = false, keep = false) => {
    setIsLoading(true);
    prospectApi
      .getProspects(
        reset ? 10 : keep ? pagination * 10 : 10,
        reset ? 0 : keep ? 0 : pagination,
        search,
        enableProspect
      )
      .then((res) => {

        if (res.data.length < 10) setDisplayLoadMore(false);
        else setDisplayLoadMore(true);

        if (!prospects || search || keep) {
          setProspects(res.data);
          setPagination(1);
        }
        else setProspects((prev) => (reset ? res.data : prev.concat(res.data)));

        setIsLoading(false);
      })
      .catch((err) => {
        setIsLoading(false);
        setMessage({
          type: "error",
          text: "Impossible de charger la liste des prospects",
        });
      });
  };

  // Active ou désactive un prospect
  const updateProspect = (id, isEnable) => {
    prospectApi
      .updateProspect(id, { enable: isEnable })
      .then(() => getProspects(false, true))
      .catch(() => {
        getProspects(true);
        setMessage({
          type: "error",
          text: "Impossible de mettre à jour le prospect",
        });
      });
  };

  // Supprime un prospect
  const removeProspect = (id) => {
    prospectApi
      .removeProspect(id)
      .then(() => {
        setMessage({
          type: "success",
          text: "Le prospect a bien été supprimé",
        });
        getProspects(false, true);
      })
      .catch(() => {
        setIsLoading(false);
        setMessage({
          type: "error",
          text: "Un problème est survenu lors de la suppression du prospect",
        });
      });
  };

  // Redirige vers la vue pour ajouter un prospect
  const goToAddProspect = () => {
    history.push("prospects/add");
  };

  // Met à jour la liste des prospects en fonction de mots dans la barre de recherche
  const onSearch = (e) => {
    setSearch(e.target.value);
  };

  // Trie la liste des prospects en fontion de la colonne sélectionnée
  const handleRequestSort = (property) => {
    const isAsc = orderBy === property && order === "asc";
    setOrder(isAsc ? "desc" : "asc");
    setOrderBy(property);
    prospects((prev) =>
      prev.sort((a, b) => {
        if (property === "Prénom") {
          return a.firstname < b.firstname ? (isAsc ? 1 : -1) : isAsc ? -1 : 1;
        }
        if (property === "Nom") {
          return a.lastname < b.lastname ? (isAsc ? 1 : -1) : isAsc ? -1 : 1;
        } else if (property === "Email") {
          return a.email < b.email ? (isAsc ? 1 : -1) : isAsc ? -1 : 1;
        } else if (property === "Adresse") {
          return a.address < b.address ? (isAsc ? 1 : -1) : isAsc ? -1 : 1;
        } else return 1;
      })
    );
  };

  const loadMore = () => {
    setPagination((prev) => prev + 1);
    getProspects();
  };

  return (
    <>
      <Grid container spacing={3} className={classes.container}>
        <Grid item xs={12}>
          <Typography variant="h6" gutterBottom>
            Prospects
            <Button
              variant="contained"
              color="primary"
              className={classes.addProposect}
              onClick={goToAddProspect}
              endIcon={<PersonAddIcon>Ajouter</PersonAddIcon>}
            >
              Ajouter
            </Button>
          </Typography>
        </Grid>
        <Grid item xs={12}>
          <Paper className={classes.filtre}>
            <TextField
              required
              id="search"
              label="Recherche"
              fullWidth
              autoComplete="search"
              onChange={onSearch}
              disabled={isLoading}
            />
            <FormGroup>
              <FormControlLabel onChange={e => setEnableProspect(prev => !prev)}
                control={<Switch defaultChecked  color="primary" value={enableProspect} disabled={isLoading}/>}
                label="Actif"
              />
            </FormGroup>
          </Paper>
        </Grid>
      </Grid>
      <Grid container spacing={3} className={classes.container}>
        <Grid item xs={12}>
          <TableContainer component={Paper}>
            <Table className={classes.table} aria-label="simple table">
              <TableHead>
                <TableRow>
                  <TableCell
                    align="center"
                    sortDirection={orderBy === "Prénom" ? order : false}
                  >
                    <TableSortLabel
                      active={orderBy === "Prénom"}
                      direction={orderBy === "Prénom" ? order : "asc"}
                      onClick={() => handleRequestSort("Prénom")}
                    >
                      {orderBy === "Prénom" ? (
                        <span className={classes.visuallyHidden}>
                          {order === "desc"
                            ? "sorted descending"
                            : "sorted ascending"}
                        </span>
                      ) : null}
                      Prénom
                    </TableSortLabel>
                  </TableCell>
                  <TableCell
                    align="center"
                    sortDirection={orderBy === "Nom" ? order : false}
                  >
                    <TableSortLabel
                      active={orderBy === "Nom"}
                      direction={orderBy === "Nom" ? order : "asc"}
                      onClick={() => handleRequestSort("Nom")}
                    >
                      {orderBy === "Nom" ? (
                        <span className={classes.visuallyHidden}>
                          {order === "desc"
                            ? "sorted descending"
                            : "sorted ascending"}
                        </span>
                      ) : null}
                      Nom
                    </TableSortLabel>
                  </TableCell>
                  <TableCell
                    align="center"
                    sortDirection={orderBy === "Email" ? order : false}
                  >
                    <TableSortLabel
                      active={orderBy === "Email"}
                      direction={orderBy === "Email" ? order : "asc"}
                      onClick={() => handleRequestSort("Email")}
                    >
                      {orderBy === "Email" ? (
                        <span className={classes.visuallyHidden}>
                          {order === "desc"
                            ? "sorted descending"
                            : "sorted ascending"}
                        </span>
                      ) : null}
                      Email
                    </TableSortLabel>
                  </TableCell>
                  <TableCell
                    align="center"
                    sortDirection={orderBy === "Adresse" ? order : false}
                  >
                    <TableSortLabel
                      active={orderBy === "Adresse"}
                      direction={orderBy === "Adresse" ? order : "asc"}
                      onClick={() => handleRequestSort("Adresse")}
                    >
                      {orderBy === "Adresse" ? (
                        <span className={classes.visuallyHidden}>
                          {order === "desc"
                            ? "sorted descending"
                            : "sorted ascending"}
                        </span>
                      ) : null}
                      Adresse
                    </TableSortLabel>
                  </TableCell>
                  <TableCell align="center">Téléphone</TableCell>
                  <TableCell align="center">Actions</TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {prospects
                  ? prospects
                      .map((prospect) => (
                        <TableRow key={prospect.id}>
                          <TableCell align="center">
                            {prospect.firstname}
                          </TableCell>
                          <TableCell align="center">
                            {prospect.lastname}
                          </TableCell>
                          <TableCell align="center">{prospect.email}</TableCell>
                          <TableCell align="center">
                            {prospect.address}
                          </TableCell>
                          <TableCell align="center">{prospect.phone}</TableCell>
                          <TableCell
                            align="center"
                            className={classes.prospectActions}
                          >
                            {prospect.enable ? (
                              <>
                                <Button
                                  variant="contained"
                                  color="primary"
                                  onClick={() =>
                                    updateProspect(prospect.id, false)
                                  }
                                >
                                  Désactiver
                                </Button>
                                <Button
                                  variant="contained"
                                  color="primary"
                                  onClick={() =>
                                    history.push(`/prospects/${prospect.id}`)
                                  }
                                >
                                  Editer
                                </Button>
                              </>
                            ) : (
                              <>
                                <Button
                                  variant="contained"
                                  color="primary"
                                  onClick={() =>
                                    updateProspect(prospect.id, true)
                                  }
                                >
                                  Activer
                                </Button>
                                <ConfirmButton
                                  title="Êtes-vous certain de vouloir supprimer ce prospect ?"
                                  content="Cette action sera irrévocable."
                                  btnText="Supprimer"
                                  onConfirm={() => removeProspect(prospect.id)}
                                />
                              </>
                            )}
                          </TableCell>
                        </TableRow>
                      ))
                  : [1, 2, 3, 4, 5, 6, 7, 8, 9, 10].map(() => (
                      <TableRow>
                        <TableCell>
                          <Skeleton variant="rect" height="48px" width="100%" />
                        </TableCell>
                        <TableCell>
                          <Skeleton variant="rect" height="48px" width="100%" />
                        </TableCell>
                        <TableCell>
                          <Skeleton variant="rect" height="48px" width="100%" />
                        </TableCell>
                        <TableCell>
                          <Skeleton variant="rect" height="48px" width="100%" />
                        </TableCell>
                        <TableCell>
                          <Skeleton variant="rect" height="48px" width="100%" />
                        </TableCell>
                        <TableCell>
                          <Skeleton variant="rect" height="48px" width="100%" />
                        </TableCell>
                      </TableRow>
                    ))}
              </TableBody>
            </Table>
            {displayLoadMore && (
              <Button
                variant="contained"
                color="primary"
                onClick={loadMore}
                className={classes.btnAddTraduction}
                fullWidth={true}
              >
                Charger plus de traduction
              </Button>
            )}
          </TableContainer>
        </Grid>
      </Grid>
      <Message
        text={message.text}
        type={message.type}
        onClose={() =>
          setMessage({
            type: "", // success, error, warning or info
            text: "",
          })
        }
      />
    </>
  );
};

export default Prospects;
