import axios from "axios";

let baseURL = process.env.NODE_ENV === "production" ?  `https://${window.location.hostname}` : "http://localhost:7000";
const getToken = () => JSON.parse(localStorage.getItem("user")) ? JSON.parse(localStorage.getItem("user")).accessToken : "";

const scrapApi = {
  getWebSiteData: (url) => {
    return axios({
      method: "post",
      baseURL,
      url: `api/scrap`,
      headers: { Authorization: `Bearer ${getToken()}` },
      data: { url }
    });
  },
  restart: () => {
    return axios({
      method: "get",
      baseURL,
      url: `api/scrap/restart`,
      // headers: { Authorization: `Bearer ${getToken()}` },
    });
  }
};

export default scrapApi;

