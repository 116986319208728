import React, { useEffect, useMemo, useRef, useState } from "react";
import { useParams } from "react-router";
import carApi from "../api/carApi";
import Crop from "../components/Crop";
import { useHistory } from "react-router-dom";
import {
  Button,
  Chip,
  CircularProgress,
  Fab,
  FormControl,
  IconButton,
  InputAdornment,
  InputLabel,
  List,
  ListItem,
  ListItemSecondaryAction,
  ListItemText,
  MenuItem,
  Paper,
  TextField,
  Tooltip,
  Typography,
} from "@material-ui/core";
import "react-responsive-carousel/lib/styles/carousel.min.css"; // requires a loader
import { Carousel } from "react-responsive-carousel";
import Message from "../components/Message";
import { makeStyles } from "@material-ui/core/styles";
import wordpressApi from "../api/wordpressApi";
import GenerateDevis from "../components/GenerateDevis";
import openPDF from "../utils/openPDF";
import NavigateNextIcon from "@material-ui/icons/NavigateNext";
import NavigateBeforeIcon from "@material-ui/icons/NavigateBefore";
import AccountBoxIcon from "@material-ui/icons/AccountBox";
import PictureAsPdfIcon from "@material-ui/icons/PictureAsPdf";
import LinkOffIcon from "@material-ui/icons/LinkOff";
import CropIcon from "@material-ui/icons/Crop";
import RotateLeftIcon from "@material-ui/icons/RotateLeft";
import DeleteIcon from "@material-ui/icons/Delete";
import ArrowBackIcon from "@material-ui/icons/ArrowBack";
import ArrowForwardIcon from "@material-ui/icons/ArrowForward";
import PublishIcon from "@material-ui/icons/Publish";
import GTranslateIcon from "@material-ui/icons/GTranslate";
import TraductionPopup from "../components/TraductionPopup";
import Accordion from "@material-ui/core/Accordion";
import AccordionSummary from "@material-ui/core/AccordionSummary";
import AccordionDetails from "@material-ui/core/AccordionDetails";
import Select from "@material-ui/core/Select";
import ExpandMoreIcon from "@material-ui/icons/ExpandMore";
import categoryCodes from "../utils/categoryCodes";
import Skeleton from "@material-ui/lab/Skeleton";
import StraightenIcon from "@material-ui/icons/Straighten";

const useStyles = makeStyles((theme) => ({
  container: {
    display: "grid",
    gridTemplateColumns: "minmax(0, 3fr) minmax(0, 1fr)",
    gridTemplateRows: "1fr",
    gridGap: theme.spacing(3),
    [theme.breakpoints.down("md")]: {
      gridTemplateColumns: "minmax(0, 2fr) minmax(0, 1fr)",
      gridTemplateRows: "1fr",
    },
    [theme.breakpoints.down("sm")]: {
      gridTemplateColumns: "minmax(0, 1fr)",
      gridTemplateRows: "1fr 1fr",
    },
  },
  paper: {
    padding: theme.spacing(3),
    display: "flex",
    flexDirection: "row",
    alignContent: "flex-start",
    flexWrap: "wrap",
    gap: theme.spacing(3),
  },
  carrouselContainer: {
    position: "relative",
    width: "100%",
  },
  carAttributes: {
    width: "100%",
    display: "flex",
    justifyContent: "center",
    flexWrap: "wrap",
    gap: theme.spacing(2),
    "& > *": {
      width: "25%",
      flexGrow: 1,
    },
  },
  fullWidth: {
    width: "100%",
  },
  carImageContainer: {
    position: "relative",
  },
  carImages: {
    maxHeight: "50vh",
    width: "auto !important",
  },
  prevCarousel: {
    position: "absolute",
    left: "2rem",
    top: "50%",
    zIndex: 5,
  },
  nextCarousel: {
    position: "absolute",
    right: "2rem",
    top: "50%",
    zIndex: 5,
  },
  carImageActions: {
    gap: "5px",
    top: "5px",
    width: "100%",
    display: "flex",
    position: "fixed",
    justifyContent: "center",
  },
  carActions: {
    width: "100%",
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    gap: "5px",
  },
  carProspects: {
    width: "100%",
  },
  prospectActions: {
    display: "flex",
    justifyContent: "center",
    gap: "5px",
  },
  carTags: {
    width: "100%",
    display: "flex",
    gap: "5px",
    justifyContent: "center",
  },
  addImage: {
    position: "absolute",
    left: "2rem",
    top: "5px",
    zIndex: 5,
  },
  btnTranslate: {
    "&:hover": { cursor: "pointer" },
  },
  loadingButtonWrapper: {
    margin: theme.spacing(1),
    position: "relative",
    width: "100%",
  },
  buttonProgress: {
    color: "blue",
    position: "absolute",
    top: "50%",
    left: "50%",
    marginTop: -12,
    marginLeft: -12,
  },
}));

// Affiche la fiche d'une voiture
const Car = ({ car, isScraping = false, onCreated }) => {
  const classes = useStyles();
  let history = useHistory();
  let { id } = useParams();
  const [isLoading, setIsLoading] = useState(false);
  const [isLoadingSave, setIsLoadingSave] = useState(false);
  const [isLoadingJW, setIsLoadingJW] = useState(false);
  const [openDevis, setOpenDevis] = useState(false);
  const [message, setMessage] = useState({
    type: "", // success, error, warning or info
    text: "",
  });
  const energies = [
    "air comprimé",
    "autre",
    "bicarburation essence bioéthanol",
    "bicarburation essence",
    "gnv",
    "bicarburation essence gpl",
    "Bio Ethanol",
    "biocarburant",
    "diesel",
    "électrique",
    "essence",
    "GPL",
    "hybride",
    "hybride diesel électrique",
    "hybride essence électrique",
    "hydrogène",
  ];
  const form = useRef(null);
  const [brand, setBrand] = useState("");
  const [model, setModel] = useState("");
  const [version, setVersion] = useState("");
  const [circulationDate, setCirculationDate] = useState("");
  const [kilometres, setKilometres] = useState("");
  const [fuel, setFuel] = useState("");
  const [gearbox, setGearbox] = useState("");
  const [price, setPrice] = useState("");
  const [color, setColor] = useState("");
  const [power, setPower] = useState("");
  const [malus, setMalus] = useState("");
  const [co2, setCo2] = useState("");
  const [saddlery, setSaddlery] = useState("");
  const [options, setOptions] = useState("");
  const [categoryId, setCategoryId] = useState(""); // catégorie de la voiture
  const [wordpressUrl, setWordpressUrl] = useState("");
  const [isDisable, setIsDisable] = useState(false);
  const [carImages, setCarImages] = useState([]);
  const [carImagesSave, setCarImagesSave] = useState([]);
  const [imageToCrop, setImageToCrop] = useState();
  const [prospects, setProspects] = useState();
  const [adUrl, setAdUrl] = useState();
  const [exportXML, setExportXML] = useState();
  const [notTranslate, setNotTranslate] = useState();
  const [carouselIndex, setCarouselIndex] = useState(0);
  const [keyToTranslate, setKeyToTranslate] = useState();
  const [caroom, setCaroom] = useState(false);
  const [motorization, setMotorization] = useState(false);
  const [finish, setFinish] = useState(false);

  useEffect(() => {
    if (id) {
      // Si la voiture existe
      getCarById(id);
    } else if (car) {
      setIsLoading(true);
      // Si la voiture vient d'être scrapée et n'est pas encore sauvegardée en bdd
      setBrand(car.brand);
      setModel(car.model);
      setVersion(car.version);
      setCirculationDate(car.circulationDate);
      setKilometres(car.kilometres.replace(/[^0-9]/g, ""));
      setFuelFromTrad(car.fuel.toLowerCase());
      setGearBoxFromTrad(car.gearbox.toLowerCase());
      setPrice(parseInt(car.price));
      setColor(car.color);
      setPower(car.power);
      setMalus(car.malus);
      setCo2(car.co2);
      setSaddlery(car.saddlery);
      setOptions(car.options);
      setWordpressUrl(car.wordpressUrl);
      setCarImages(car.images);
      setCarImagesSave(JSON.parse(JSON.stringify(car.images)));
      setAdUrl(car.adUrl);
      setNotTranslate(car.notTranslate);
      setCaroom(car.caroom);
      setMotorization(car.motorization);
      setFinish(car.finish);
      setTimeout(false);
      setIsLoading(false);
    }
    return () => {
      setCarouselIndex(0);
      setCarImages([]);
      setCarImagesSave([]);
      setKeyToTranslate();
    };
  }, [car, id]);

  // Récupère les information liées à la voiture
  const getCarById = (id) => {
    setIsLoading(true);
    carApi
      .getCar(id)
      .then((res) => {
        let car = res.data;
        setBrand(car.brand);
        setModel(car.model);
        setVersion(car.version);
        setCirculationDate(car.circulationDate);
        setKilometres(car.kilometres);
        setFuel(car.fuel);
        setGearbox(car.gearbox);
        setPrice(car.price);
        setColor(car.color);
        setPower(car.power);
        setMalus(car.malus);
        setCo2(car.co2);
        setSaddlery(car.saddlery);
        setOptions(car.options);
        setCategoryId(car.categoryId);
        setWordpressUrl(car.wordpressUrl);
        setIsDisable(car.isDisable);
        setCarImagesFromUrl(car.carImages.map((i) => i.url));
        setProspects(car.prospects);
        setAdUrl(car.adUrl);
        setExportXML(car.exportXML);
        setCaroom(car.caroom);
        setMotorization(car.motorization);
        setFinish(car.finish);
      })
      .catch(() => {
        setMessage({
          type: "error",
          text: "Impossible de récupérer les informations liées avec voiture",
        });
      })
      .finally(() => {
        setIsLoading(false);
      });
  };

  const b64toBlob = (b64Data, contentType = "image/jpeg", sliceSize = 512) => {
    const byteCharacters = atob(b64Data.split(",")[1]);
    const byteArrays = [];

    for (let offset = 0; offset < byteCharacters.length; offset += sliceSize) {
      const slice = byteCharacters.slice(offset, offset + sliceSize);

      const byteNumbers = new Array(slice.length);
      for (let i = 0; i < slice.length; i++) {
        byteNumbers[i] = slice.charCodeAt(i);
      }

      const byteArray = new Uint8Array(byteNumbers);
      byteArrays.push(byteArray);
    }

    const blob = new Blob(byteArrays, { type: contentType });
    return blob;
  };

  const urlToBase64 = (src) => {
    return new Promise((resolve, reject) => {
      try {
        var img = new Image();
        img.crossOrigin = "anonymous";
        img.onload = function () {
          var canvas = document.createElement("CANVAS");
          var ctx = canvas.getContext("2d");
          var dataURL;
          canvas.height = this.naturalHeight;
          canvas.width = this.naturalWidth;
          ctx.drawImage(this, 0, 0);
          dataURL = canvas.toDataURL("image/jpeg");
          resolve(dataURL);
        };
        img.src = src;
        if (img.complete || img.complete === undefined) {
          img.src =
            "data:image/jpeg;base64,R0lGODlhAQABAIAAAAAAAP///ywAAAAAAQABAAACAUwAOw==";
          img.src = src;
        }
      } catch (err) {
        reject(err);
      }
    });
  };

  const setCarImagesFromUrl = (urls) => {
    Promise.all(urls.map((url) => urlToBase64(url)))
      .then((base64) => {
        setCarImages(base64);
        setCarImagesSave(JSON.parse(JSON.stringify(base64)));
      })
      .catch(() => {
        setCarImages([]);
        setCarImagesSave([]);
        setMessage({
          type: "error",
          text: "Erreur lors du chargement des images (essayez de re-charger l'annonce)",
        });
      });
  };

  const setFuelFromTrad = (trad) => {
    if (trad.includes("électrique")) setFuel("électrique");
    else if (trad.includes("essence")) setFuel("essence");
    else if (trad.includes("diesel")) setFuel("diesel");
    else if (trad.includes("hybride")) setFuel("hybride");
    else setFuel(trad);
  };

  const setGearBoxFromTrad = (trad) => {
    if (trad.includes("manuelle")) setGearbox("manuelle");
    else if (trad.includes("automatique")) setGearbox("automatique");
    else if (trad.includes("séquentielle")) setGearbox("séquentielle");
    else setGearbox(trad);
  };

  const onChangeAdUrl = (e) => setAdUrl(e.target.value);
  const onChangeBrand = (e) => setBrand(e.target.value);
  const onChangeModel = (e) => setModel(e.target.value);
  const onChangeVersion = (e) => setVersion(e.target.value);
  const onChangeCirculationDate = (e) => setCirculationDate(e.target.value);
  const onChangeKilometres = (e) => setKilometres(e.target.value);
  const onChangeFuel = (e) => setFuel(e.target.value);
  const onChangeGearbox = (e) => setGearbox(e.target.value);
  const onChangePrice = (e) => setPrice(e.target.value);
  const onChangeColor = (e) => setColor(e.target.value);
  const onChangePower = (e) => setPower(e.target.value);
  const onChangeMalus = (e) => setMalus(e.target.value);
  const onChangeCo2 = (e) => setCo2(e.target.value);
  const onChangeSaddlery = (e) => setSaddlery(e.target.value);
  const onChangeOptions = (e) => setOptions(e.target.value);
  const onChangeCategory = (e) => setCategoryId(e.target.value);
  const onChangeExportXML = (e) => setExportXML(e.target.value);
  const onChangeCaroom = (e) => setCaroom(e.target.value);
  const onChangeMotorization = (e) => setMotorization(e.target.value);
  const onChangeFinish = (e) => setFinish(e.target.value);

  const onCrop = (image, i) => {
    setImageToCrop({ image, i });
  };

  // Recharge la photo de base
  const onReset = (i) => {
    setCarImages((prev) => {
      prev[i] = carImagesSave[i];
      prev = JSON.parse(JSON.stringify(prev));
      setCarImages(prev);
    });
  };

  // Met à jour l'images dans la liste d'images d'un voitre lorsqu'elle vient d'être modifiée
  const onCropped = (image, i) => {
    setCarImages((prev) => {
      prev[i] = image;
      setCarImages(prev);
    });
    setImageToCrop(null);
  };

  // Annule le crop
  const onCropCanceled = () => setImageToCrop(null);

  // Modifier les informations d'un voiture ou créé une nouvelle voiture
  const onSubmit = () => {
    if (
      circulationDate.length != 7 ||
      (circulationDate.length == 7 && circulationDate[2] != "/")
    ) {
      setMessage({
        isOpen: true,
        type: "error",
        text: "Mauvais format pour la date de mise en circulation, exemple: 09/2022",
      });
    } else if (form.current.checkValidity()) {
      if (id) {
        setIsLoadingSave(true);
        // Si la voiture existe déjà
        carApi
          .updateCar(id, {
            brand,
            model,
            version,
            circulationDate,
            kilometres,
            fuel,
            gearbox,
            price,
            color,
            power,
            malus,
            co2,
            saddlery,
            options,
            categoryId,
            exportXML,
            caroom,
            motorization,
            finish,
          })
          .then(() => carApi.removeImages(id))
          .then(() => {
            return carImages.reduce((acc, base64, index) => {
              return acc
                .then(() => {
                  return carApi.uploadImage(
                    id,
                    b64toBlob(base64),
                    `${id}_${brand.replace(/\s/g, "_")}_${model.replace(
                      /\s/g,
                      "_"
                    )}_${index}.jpeg`
                  );
                })
                .catch(() => {
                  setMessage({
                    isOpen: true,
                    type: "error",
                    text:
                      "Impossible de sauvegarder l'image à la position " +
                      index,
                  });
                  return Promise.resolve();
                });
            }, Promise.resolve());
          })
          .then(() => {
            setIsLoadingSave(false);
            setMessage({
              isOpen: true,
              type: "success",
              text: "Voiture enregistrée avec succes",
            });
          })
          .catch(() => {
            setIsLoadingSave(false);
            setMessage({
              isOpen: true,
              type: "error",
              text: "Echec lors de l'enregistrement",
            });
          });
      } else {
        // Si c'est une nouvelle voiture
        if ((carImages && carImages.length == 0) || !carImages)
          setMessage({
            isOpen: true,
            type: "warning",
            text: "Aucunes images détéctées",
          });
        else {
          setIsLoadingSave(true);
          carApi
            .addCar({
              brand,
              model,
              version,
              circulationDate,
              kilometres,
              fuel,
              gearbox,
              price,
              color,
              power,
              malus,
              co2,
              saddlery,
              options,
              categoryId,
              adUrl,
              exportXML,
              caroom,
              motorization,
              finish,
            })
            .then((res) => {
              return carImages
                .reduce((acc, base64, index) => {
                  return acc
                    .then(() => {
                      return carApi.uploadImage(
                        res.data.id,
                        b64toBlob(base64),
                        `${res.data.id}_${brand.replace(
                          /\s/g,
                          "_"
                        )}_${model.replace(/\s/g, "_")}_${index}.jpeg`
                      );
                    })
                    .catch(() => {
                      setMessage({
                        isOpen: true,
                        type: "error",
                        text:
                          "Impossible de sauvegarder l'image à la position " +
                          index,
                      });
                      return Promise.resolve();
                    });
                }, Promise.resolve())
                .then(wordpressApi.addPost(res.data.id));
            })
            .then(() => {
              onCreated();
            })
            .catch((err) => {
              setMessage({
                isOpen: true,
                type: "error",
                text: "Echec lors de la création de la voiture: " + err,
              });
            })
            .finally(() => {
              setIsLoadingSave(false);
            });
        }
      }
    } else {
      setMessage({
        isOpen: true,
        type: "warning",
        text: "Veuillez remplir tous les champs obligatoires (*)",
      });
    }
  };

  // Créer un article sur le wordpress Jung Werth
  const onWordpressPostCreate = () => {
    setIsLoadingJW(true);
    wordpressApi
      .addPost(id)
      .then(() => {
        setIsLoadingJW(false);
        getCarById(id);
        setMessage({
          isOpen: true,
          type: "success",
          text: "Annonce créée avec succès",
        });
      })
      .catch(() => {
        setIsLoadingJW(false);
        setMessage({
          isOpen: true,
          type: "warning",
          text: "Erreur lors de la création de l'article",
        });
      });
  };

  // Active ou desactive un article sur le wordpress Jung Werth
  const onWordpressPostDisableEnable = (isDisable) => {
    setIsLoadingJW(true);
    wordpressApi
      .disableEnablePost(id, isDisable)
      .then(() => {
        setIsLoadingJW(false);
        getCarById(id);
        setMessage({
          isOpen: true,
          type: "success",
          text: "Annonce edité avec succès",
        });
      })
      .catch(() => {
        setIsLoadingJW(false);
        setMessage({
          isOpen: true,
          type: "warning",
          text: "Erreur lors de l'édition de l'article",
        });
      });
  };

  // Affiche l'article lié à la voiture sur le wordpress Jung Werth
  const onWordpressPostView = () => {
    if (wordpressUrl) window.open(wordpressUrl);
  };

  // Affiche l'offre initiale de la voiture
  const onAdsView = () => {
    if (adUrl) window.open(adUrl);
  };

  // Ouvre la pop pour générer un devis
  const onOpenDevis = () => setOpenDevis(true);
  // Callback lorsque la pop pour générer le devis s'est fermée
  const onCloseDevis = (sucess = false) => {
    setOpenDevis(false);
    if (sucess && id) getCarById(id);
  };

  // Change la position du carrousel vers l'image précédente
  const onPrevCarrousel = () => {
    if (carouselIndex - 1 < 0) return;
    setCarouselIndex((index) => index - 1);
  };

  // Change la position du carrousel vers l'image suivante
  const onNextCarrousel = () => {
    if (carouselIndex + 1 === carImages.length) return;
    setCarouselIndex((index) => index + 1);
  };

  // Supprimer le prospect de la voitre
  const onRemoveProspect = (prospectId) => {
    let _prospects = prospects.filter((prospect) => prospect.id !== prospectId);
    setProspects(_prospects);
    carApi
      .updateCarProspects(
        id,
        _prospects.map((p) => p.id)
      )
      .then(() => {
        getCarById(id);
        setMessage({
          isOpen: true,
          type: "success",
          text: "Prospect supprimé avec succès",
        });
      })
      .catch(() => {
        getCarById(id);
        setMessage({
          isOpen: true,
          type: "error",
          text: "Error lors de la suppression du prospect",
        });
      });
  };

  // Supprime l'image liée à la voiture
  const onDeleteImage = (i) => {
    setCarImages((prev) => prev.filter((img, index) => index !== i));
    setCarImagesSave((prev) => prev.filter((img, index) => index !== i));
    setCarouselIndex(i - 1 < 0 ? 0 : i - 1);
  };

  // Change l'image de position dans la liste d'images associée à la voiture
  const onMoveImage = (old_index, new_index) => {
    setCarImages((arr) => {
      let newArr = [...arr];
      newArr.splice(new_index, 0, newArr.splice(old_index, 1)[0]);
      return newArr;
    });
    setCarImagesSave((arr) => {
      let newArr = [...arr];
      newArr.splice(new_index, 0, newArr.splice(old_index, 1)[0]);
      return newArr;
    });
    setCarouselIndex(new_index);
  };

  // Gère l'upload d'image personnalisée pour la voiture
  const onSelectFile = (e) => {
    if (e.target.files && e.target.files.length > 0) {
      setCarouselIndex((prev) => (prev == 0 ? prev : carImages.length - 1));
      const reader = new FileReader();
      reader.readAsDataURL(e.target.files[0]);
      reader.addEventListener("load", () => {
        setCarImages((prev) => {
          prev.push(reader.result);
          return [...prev];
        });
        setCarImagesSave((prev) => {
          prev.push(reader.result);
          return [...prev];
        });
      });
    }
  };

  // ouvre la pop up lié à la traduction
  const onOpenTraduction = (key) => {
    setKeyToTranslate(key);
  };

  // Callback de la fermeture de la pop lié à la traduction
  const onCloseTraductions = (newTraductions) => {
    setNotTranslate((prev) => {
      prev[keyToTranslate] = newTraductions;
      return prev;
    });
    setKeyToTranslate(null);
  };

  const carrouselButtons = useMemo(() => {
    let carrouselButtons = {
      isPrevEnable: false,
      isNextEnable: true,
    };

    if (carouselIndex == 0 || carImages.length < 2)
      carrouselButtons.isPrevEnable = false;
    else carrouselButtons.isPrevEnable = true;

    if (carouselIndex == carImages.length - 1 || carImages.length < 2)
      carrouselButtons.isNextEnable = false;
    else carrouselButtons.isNextEnable = true;

    return carrouselButtons;
  }, [carouselIndex, carImages]);

  return (
    <>
      <div className={classes.container}>
        <Paper className={classes.paper}>
          <form ref={form} className={classes.carAttributes}>
            {!isScraping && !isLoading ? (
              <TextField
                required
                id="adUrl"
                name="adUrl"
                label="Url de l'annonce"
                fullWidth
                autoComplete="adUrl"
                value={adUrl}
                onChange={onChangeAdUrl}
                className={classes.fullWidth}
              />
            ) : (
              <Skeleton variant="rect" height="48px" />
            )}
            {!isScraping && !isLoading ? (
              <TextField
                required
                id="brand"
                name="brand"
                label="Marque"
                fullWidth
                autoComplete="brand"
                value={brand}
                onChange={onChangeBrand}
              />
            ) : (
              <Skeleton variant="rect" height="48px" />
            )}

            {!isScraping && !isLoading ? (
              <TextField
                required
                id="model"
                name="model"
                label="Modèle"
                fullWidth
                autoComplete="model"
                value={model}
                onChange={onChangeModel}
              />
            ) : (
              <Skeleton variant="rect" height="48px" />
            )}

            {!isScraping && !isLoading ? (
              <TextField
                id="version"
                name="version"
                label="Version"
                fullWidth
                autoComplete="version"
                value={version}
                onChange={onChangeVersion}
              />
            ) : (
              <Skeleton variant="rect" height="48px" />
            )}

            {!isScraping && !isLoading ? (
              <TextField
                required
                id="circulationDate"
                name="circulationDate"
                label="Date de mise en circulation (ex: 09/2022)"
                fullWidth
                autoComplete="circulationDate"
                value={circulationDate}
                onChange={onChangeCirculationDate}
              />
            ) : (
              <Skeleton variant="rect" height="48px" />
            )}

            {!isScraping && !isLoading ? (
              <TextField
                required
                id="kilometres"
                name="kilometres"
                label="Nombre de kilomètres"
                fullWidth
                type="number"
                autoComplete="kilometres"
                value={kilometres}
                onChange={onChangeKilometres}
              />
            ) : (
              <Skeleton variant="rect" height="48px" />
            )}

            {!isScraping && !isLoading ? (
              <FormControl className={classes.formControl} required>
                <InputLabel id="demo-simple-select-label">Energie</InputLabel>
                <Select
                  labelId="demo-simple-select-label"
                  id="demo-simple-select"
                  placeholder={fuel}
                  value={fuel}
                  required
                  onChange={onChangeFuel}
                  InputProps={
                    notTranslate &&
                    "fuel" in notTranslate &&
                    Array.isArray(notTranslate.fuel) &&
                    notTranslate.fuel.length > 0 && {
                      endAdornment: (
                        <InputAdornment
                          position="end"
                          className={classes.btnTranslate}
                          onClick={() => onOpenTraduction("fuel")}
                        >
                          <GTranslateIcon />
                        </InputAdornment>
                      ),
                    }
                  }
                >
                  {energies.map((energie) => (
                    <MenuItem value={energie}>{energie}</MenuItem>
                  ))}
                </Select>
              </FormControl>
            ) : (
              <Skeleton variant="rect" height="48px" />
            )}

            {!isScraping && !isLoading ? (
              <FormControl className={classes.formControl} required>
                <InputLabel id="demo-simple-select-label">
                  Boite de vitesse
                </InputLabel>
                <Select
                  labelId="demo-simple-select-label"
                  id="gearbox"
                  placeholder={gearbox}
                  value={gearbox}
                  required
                  onChange={onChangeGearbox}
                  InputProps={
                    notTranslate &&
                    "gearbox" in notTranslate &&
                    Array.isArray(notTranslate.gearbox) &&
                    notTranslate.gearbox.length > 0 && {
                      endAdornment: (
                        <InputAdornment
                          position="end"
                          className={classes.btnTranslate}
                          onClick={() => onOpenTraduction("gearbox")}
                        >
                          <GTranslateIcon />
                        </InputAdornment>
                      ),
                    }
                  }
                >
                  <MenuItem value="manuelle">manuelle</MenuItem>
                  <MenuItem value="automatique">automatique</MenuItem>
                  <MenuItem value="séquentielle">séquentielle</MenuItem>
                </Select>
              </FormControl>
            ) : (
              <Skeleton variant="rect" height="48px" />
            )}

            {!isScraping && !isLoading ? (
              <TextField
                required
                id="price"
                name="price"
                label="Prix (€)"
                type="number"
                fullWidth
                autoComplete="price"
                value={price}
                onChange={onChangePrice}
              />
            ) : (
              <Skeleton variant="rect" height="48px" />
            )}

            {!isScraping && !isLoading ? (
              <TextField
                required
                id="color"
                name="color"
                label="Couleur"
                fullWidth
                autoComplete="color"
                value={color}
                onChange={onChangeColor}
                InputProps={
                  notTranslate &&
                  "color" in notTranslate &&
                  Array.isArray(notTranslate.color) &&
                  notTranslate.color.length > 0 && {
                    endAdornment: (
                      <InputAdornment
                        position="end"
                        className={classes.btnTranslate}
                        onClick={() => onOpenTraduction("color")}
                      >
                        <GTranslateIcon />
                      </InputAdornment>
                    ),
                  }
                }
              />
            ) : (
              <Skeleton variant="rect" height="48px" />
            )}

            {!isScraping && !isLoading ? (
              <TextField
                required
                id="power"
                name="power"
                label={`Puissance (PS) ${isNaN(power) ? `-> ${power}` : ""}`}
                type="number"
                fullWidth
                autoComplete="power"
                value={power}
                onChange={onChangePower}
                InputProps={
                  !isNaN(power) && {
                    endAdornment: (
                      <InputAdornment
                        position="end"
                        className={classes.btnTranslate}
                        onClick={() =>
                          setPower(parseInt(parseInt(power) * 1.35962))
                        }
                      >
                        <StraightenIcon />
                      </InputAdornment>
                    ),
                  }
                }
              />
            ) : (
              <Skeleton variant="rect" height="48px" />
            )}

            {!isScraping && !isLoading ? (
              <TextField
                id="malus"
                name="malus"
                label="Coût du certificat d'immatriculation (€)"
                fullWidth
                autoComplete="malus"
                value={malus}
                onChange={onChangeMalus}
                type="number"
              />
            ) : (
              <Skeleton variant="rect" height="48px" />
            )}

            {!isScraping && !isLoading ? (
              <TextField
                id="co2"
                name="co2"
                label="Emission en CO2"
                fullWidth
                autoComplete="co2"
                value={co2}
                onChange={onChangeCo2}
              />
            ) : (
              <Skeleton variant="rect" height="48px" />
            )}

            {!isScraping && !isLoading ? (
              <TextField
                id="saddlery"
                name="saddlery"
                label="Sellerie"
                fullWidth
                autoComplete="saddlery"
                value={saddlery}
                onChange={onChangeSaddlery}
              />
            ) : (
              <Skeleton variant="rect" height="48px" />
            )}

            {!isScraping && !isLoading ? (
              <FormControl className={classes.formControl} required>
                <InputLabel id="demo-simple-select-label">Catégorie</InputLabel>
                <Select
                  labelId="demo-simple-select-label"
                  id="demo-simple-select"
                  value={categoryId}
                  onChange={onChangeCategory}
                >
                  {categoryCodes.map((cat) => (
                    <MenuItem value={cat.code}>{cat.name}</MenuItem>
                  ))}
                </Select>
              </FormControl>
            ) : (
              <Skeleton variant="rect" height="48px" />
            )}

            {!isScraping && !isLoading ? (
              <FormControl className={classes.formControl} required>
                <InputLabel id="demo-simple-select-label">
                  Activer/désactiver Leboncoin
                </InputLabel>
                <Select
                  labelId="demo-simple-select-label"
                  id="demo-simple-select"
                  value={exportXML}
                  onChange={onChangeExportXML}
                >
                  <MenuItem value={true}>Activer</MenuItem>
                  <MenuItem value={false}>Désactiver</MenuItem>
                </Select>
              </FormControl>
            ) : (
              <Skeleton variant="rect" height="48px" />
            )}

            {!isScraping && !isLoading ? (
              <TextField
                id="motorization"
                name="motorization"
                label="Motorisation"
                fullWidth
                autoComplete="motorization"
                value={motorization}
                onChange={onChangeMotorization}
              />
            ) : (
              <Skeleton variant="rect" height="48px" />
            )}

            {!isScraping && !isLoading ? (
              <TextField
                id="finish"
                name="finish"
                label="Finition"
                fullWidth
                autoComplete="finish"
                value={finish}
                onChange={onChangeFinish}
              />
            ) : (
              <Skeleton variant="rect" height="48px" />
            )}

            {!isScraping && !isLoading ? (
              <FormControl className={classes.formControl} required>
                <InputLabel id="demo-simple-select-label">
                  Activer/désactiver Caroom
                </InputLabel>
                <Select
                  labelId="demo-simple-select-label"
                  id="demo-simple-select"
                  value={caroom}
                  onChange={onChangeCaroom}
                >
                  <MenuItem value={true}>Activer</MenuItem>
                  <MenuItem value={false}>Désactiver</MenuItem>
                </Select>
              </FormControl>
            ) : (
              <Skeleton variant="rect" height="48px" />
            )}

            {!isScraping && !isLoading ? (
              <Accordion className={classes.fullWidth}>
                <AccordionSummary
                  expandIcon={<ExpandMoreIcon />}
                  aria-controls="panel1a-content"
                  id="panel1a-header"
                >
                  <Typography className={classes.heading}>
                    Découvrir les options *
                  </Typography>
                </AccordionSummary>
                <AccordionDetails>
                  <TextField
                    required
                    id="options"
                    name="options"
                    label="Options"
                    fullWidth
                    autoComplete="options"
                    value={options}
                    onChange={onChangeOptions}
                    multiline={true}
                    InputProps={
                      notTranslate &&
                      "options" in notTranslate &&
                      Array.isArray(notTranslate.options) &&
                      notTranslate.options.length > 0 && {
                        endAdornment: (
                          <InputAdornment
                            position="end"
                            className={classes.btnTranslate}
                            onClick={() => onOpenTraduction("options")}
                          >
                            <GTranslateIcon />
                          </InputAdornment>
                        ),
                      }
                    }
                  />
                </AccordionDetails>
              </Accordion>
            ) : (
              <Skeleton variant="rect" width="100%" height="48px" />
            )}
          </form>

          {isLoading || isScraping ? (
            <Skeleton variant="rect" width="100%" height="400px" />
          ) : carImages && carImages.length > 0 ? (
            <div className={classes.carrouselContainer}>
              <label htmlFor="icon-button-file">
                <input
                  accept="image/*"
                  hidden
                  id="icon-button-file"
                  type="file"
                  onChange={onSelectFile}
                />
                <Tooltip title="Ajouter une image">
                  <Fab
                    className={classes.addImage}
                    color="primary"
                    aria-label="upload picture"
                    component="span"
                  >
                    <PublishIcon />
                  </Fab>
                </Tooltip>
              </label>
              {carrouselButtons.isPrevEnable && (
                <Fab
                  color="primary"
                  aria-label="prev"
                  className={classes.prevCarousel}
                  onClick={onPrevCarrousel}
                >
                  <NavigateBeforeIcon />
                </Fab>
              )}
              {carrouselButtons.isNextEnable && (
                <Fab
                  color="primary"
                  aria-label="next"
                  className={classes.nextCarousel}
                  onClick={onNextCarrousel}
                >
                  <NavigateNextIcon />
                </Fab>
              )}

              <Carousel
                showThumbs={false}
                showArrows={false}
                renderThumbs={false}
                showIndicators={false}
                selectedItem={carouselIndex}
              >
                {carImages.map((image, i) => (
                  <div key={i} className={classes.carImageContainer}>
                    <img
                      src={image}
                      alt="voiture"
                      className={classes.carImages}
                    />
                    <section className={classes.carImageActions}>
                      <Tooltip title="Changer l'odre">
                        <div>
                          <Fab
                            color="primary"
                            aria-label="add"
                            disabled={!carrouselButtons.isPrevEnable}
                            onClick={() => onMoveImage(i, i - 1)}
                          >
                            <ArrowBackIcon />
                          </Fab>
                        </div>
                      </Tooltip>
                      <Tooltip title="Ajuster">
                        <Fab
                          color="primary"
                          aria-label="add"
                          onClick={() => onCrop(image, i)}
                        >
                          <CropIcon />
                        </Fab>
                      </Tooltip>
                      <Tooltip title="Annuler les modifications">
                        <Fab
                          color="primary"
                          aria-label="add"
                          onClick={() => onReset(i)}
                        >
                          <RotateLeftIcon />
                        </Fab>
                      </Tooltip>
                      <Tooltip title="Supprimer l'image">
                        <Fab
                          color="primary"
                          aria-label="add"
                          onClick={() => onDeleteImage(i)}
                        >
                          <DeleteIcon />
                        </Fab>
                      </Tooltip>
                      <Tooltip title="Changer l'odre">
                        <div>
                          <Fab
                            color="primary"
                            aria-label="add"
                            disabled={!carrouselButtons.isNextEnable}
                            onClick={() => onMoveImage(i, i + 1)}
                          >
                            <ArrowForwardIcon />
                          </Fab>
                        </div>
                      </Tooltip>
                    </section>
                  </div>
                ))}
              </Carousel>
            </div>
          ) : (
            <div className={classes.carrouselContainer}>
              <Button
                variant="contained"
                component="label"
                color="primary"
                fullWidth
              >
                Ajouter des images
                <input
                  accept="image/*"
                  hidden
                  id="icon-button-file"
                  type="file"
                  onChange={onSelectFile}
                />
              </Button>
            </div>
          )}
          <div>
            {imageToCrop && (
              <Crop
                imageToCrop={imageToCrop}
                onCropped={onCropped}
                onCanceled={onCropCanceled}
              />
            )}
          </div>
        </Paper>
        <Paper className={classes.paper}>
          <section className={classes.carTags}>
            {wordpressUrl && <Chip label="Jung Werth" color="primary" />}
            {false && <Chip label="Leboncoin" color="primary" />}
            {Array.isArray(prospects) && prospects.length > 0 && (
              <Chip label="Prospect lié" color="primary" />
            )}
            {false && <Chip label="Offre envoyé" color="primary" />}
          </section>
          <section className={classes.carActions}>
            <div className={classes.loadingButtonWrapper}>
              <Button
                variant="contained"
                color="primary"
                onClick={onSubmit}
                fullWidth
                disabled={isLoadingSave}
              >
                {id ? "Enregistrer" : "Créer"}
              </Button>
              {isLoadingSave && (
                <CircularProgress
                  size={24}
                  className={classes.buttonProgress}
                />
              )}
            </div>
            {id && (
              <>
                <Button
                  variant="contained"
                  color="primary"
                  onClick={onOpenDevis}
                  fullWidth
                  className={classes.loadingButtonWrapper}
                >
                  Générer un devis
                </Button>
                {!wordpressUrl && (
                  <div className={classes.loadingButtonWrapper}>
                    <Button
                      variant="contained"
                      color="primary"
                      onClick={onWordpressPostCreate}
                      disabled={isLoadingJW}
                      fullWidth
                    >
                      Créer un article Jung Werth
                    </Button>
                    {isLoadingJW && (
                      <CircularProgress
                        size={24}
                        className={classes.buttonProgress}
                      />
                    )}
                  </div>
                )}
                {wordpressUrl && (
                  <>
                    <Button
                      variant="contained"
                      color="primary"
                      onClick={onWordpressPostView}
                      fullWidth
                    >
                      Voir l'annonce sur JW
                    </Button>
                    <div className={classes.loadingButtonWrapper}>
                      <Button
                        variant="contained"
                        color="primary"
                        onClick={() => onWordpressPostDisableEnable(!isDisable)}
                        disabled={isLoadingJW}
                        fullWidth
                      >
                        {isDisable
                          ? "Activer l'annonce sur JW"
                          : "Désactiver l'annonce sur JW"}
                      </Button>
                      {isLoadingJW && (
                        <CircularProgress
                          size={24}
                          className={classes.buttonProgress}
                        />
                      )}
                    </div>
                  </>
                )}

                <Button
                  variant="contained"
                  color="primary"
                  onClick={onAdsView}
                  fullWidth
                >
                  Voir l'offre
                </Button>
              </>
            )}
          </section>
          {id && (
            <section className={classes.carProspects}>
              <Typography variant="h6" gutterBottom>
                Prospect(s) lié(s):
              </Typography>
              <div>
                {prospects ? (
                  <List>
                    {prospects.length > 0 &&
                      prospects.map((prospect) => (
                        <ListItem key={prospect.id}>
                          <ListItemText
                            primary={`${prospect.firstname} ${prospect.lastname}`}
                          />
                          <ListItemSecondaryAction>
                            <Tooltip title="Afficher la fiche prospect">
                              <IconButton
                                aria-label="prospect"
                                onClick={() =>
                                  history.push(`/prospects/${prospect.id}`)
                                }
                              >
                                <AccountBoxIcon />
                              </IconButton>
                            </Tooltip>
                            <Tooltip title="Afficher le devis (PDF)">
                              <IconButton
                                aria-label="pdf"
                                onClick={() => openPDF(id, prospect.id)}
                              >
                                <PictureAsPdfIcon />
                              </IconButton>
                            </Tooltip>
                            <Tooltip title="Dissocier la prospect du véhicule">
                              <IconButton
                                aria-label="unlink"
                                onClick={() => onRemoveProspect(prospect.id)}
                              >
                                <LinkOffIcon />
                              </IconButton>
                            </Tooltip>
                          </ListItemSecondaryAction>
                        </ListItem>
                      ))}
                  </List>
                ) : (
                  <Skeleton variant="rect" width="100%" height="88px" />
                )}
              </div>
            </section>
          )}
        </Paper>
        <Message
          text={message.text}
          type={message.type}
          onClose={() =>
            setMessage({
              type: "", // success, error, warning or info
              text: "",
            })
          }
        />
        {id && (
          <GenerateDevis
            isOpen={openDevis}
            car={{
              id,
              brand,
              model,
              circulationDate,
              kilometres,
              fuel,
              gearbox,
              price,
              color,
              power,
              malus,
              co2,
              saddlery,
              options,
              categoryId,
              carImages,
              exportXML,
            }}
            onClose={onCloseDevis}
          />
        )}
        {keyToTranslate && (
          <TraductionPopup
            isOpen={keyToTranslate ? true : false}
            onClose={onCloseTraductions}
            traductions={notTranslate}
            keyToTranslate={keyToTranslate}
          />
        )}
      </div>
    </>
  );
};

export default Car;
