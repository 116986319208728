import React, { useState } from "react";
import scrapApi from "../api/scrapApi";
import Car from "./Car";
import { useHistory } from "react-router-dom";
import { Button, Grid, Paper, TextField, Typography } from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";
import Message from "../components/Message";
import { useCar } from "../contexts/carContext";

const useStyles = makeStyles((theme) => ({
  container: {
    marginBottom: "40px",
  },
  paper: {
    padding: theme.spacing(3),
    display: "flex",
    overflow: "auto",
    flexDirection: "column",
  },
  btnScan: {
    display: "flex",
    alignItems: "flex-end",
  },
}));

// Affiche le formulaire de scraping
const Scrap = () => {
  const classes = useStyles();
  let history = useHistory();
  const { getCars, setPrevIndex } = useCar();
  const [url, setUrl] = useState("");
  const [isScraping, setIsScraping] = useState(false);
  const [scrapData, setScrapData] = useState();
  const [message, setMessage] = useState({
    type: "", // success, error, warning or info
    text: "",
  });

  const onChangeUrl = (e) => setUrl(e.target.value);

  // Déclanche le scraping
  const onScrap = () => {
    setIsScraping(true);
    setScrapData(null);
    scrapApi
      .getWebSiteData(url)
      .then((res) => {
        let car = res.data;
        car.adUrl = url;
        setScrapData(car);
        setIsScraping(false);
      })
      .catch((err) => {
        setIsScraping(false);
        setMessage({
          type: "error",
          text: err.response ? err.response.data : "Une erreur est survenue lors de l'analyse du site",
        });
      });
  };

  const onCarCreated = () => {
    setScrapData(null);
    setUrl(null);
    setPrevIndex(null);
    getCars(true);
    history.push("/cars");
  };

  return (
    <>
      <Grid
        container
        spacing={3}
        component={Paper}
        elevation={6}
        square
        className={classes.container}
      >
        <Grid item xs={12}>
          <Typography variant="h6" gutterBottom>
            Scanner une url
          </Typography>
          <Grid container spacing={3}>
            <Grid item xs={12} md={10}>
              <TextField
                required
                id="url"
                label="Url"
                fullWidth
                autoComplete="url"
                value={url}
                onChange={onChangeUrl}
              />
            </Grid>
            <Grid item xs={12} md={2} className={classes.btnScan}>
              <Button
                variant="contained"
                color="primary"
                onClick={onScrap}
                disabled={isScraping}
              >
                Scanner
              </Button>
            </Grid>
          </Grid>
          <Grid item xs={12}>
            <p>
              Exemple:
              https://www.mercedes-benz.de/passengercars/mercedes-benz-cars/vehicle-search.html/u/used-vehicles/d/details/?brand=Mercedes-Benz&class=C-Klasse&engine=C-220&bodyType=T-Modell&date=09-2010&fuel=Diesel&km=315851&price=4888&id=d201ea01f282db2eb6c4eca2bb77940b
            </p>
          </Grid>
        </Grid>
      </Grid>
      {(isScraping || scrapData) && <Car car={scrapData} isScraping={isScraping} onCreated={onCarCreated} />}

      <Message
        text={message.text}
        type={message.type}
        onClose={() =>
          setMessage({
            type: "", // success, error, warning or info
            text: "",
          })
        }
      />
    </>
  );
};

export default Scrap;
