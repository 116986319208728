import React, { useEffect, useState } from "react";
import PropTypes from "prop-types";
import carApi from "../api/carApi";
import prospectApi from "../api/prospectApi";
import Button from "@material-ui/core/Button";
import Dialog from "@material-ui/core/Dialog";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import DialogTitle from "@material-ui/core/DialogTitle";
import TextField from "@material-ui/core/TextField";
import Autocomplete from "@material-ui/lab/Autocomplete";
import pdfApi from "../api/pdfApi";
import { LinearProgress, makeStyles } from "@material-ui/core";
import userApi from "../api/userApi";
import { useAuth } from "../contexts/auth";

const useStyles = makeStyles((theme) => ({
  section: {
    marginBottom: "40px",
    display: "flex",
    flexDirection: "column",
    gap: "5px",
  },
}));

export default function GenerateDevis({ isOpen, car, prospectId, onClose }) {
  const classes = useStyles();
  const [loading, setLoading] = useState(true);
  const [searching, setSearching] = useState(false);
  const [search, setSearch] = useState("");
  const [users, setUsers] = useState([]);
  const [cars, setCars] = useState();
  const [prospects, setProspects] = useState();
  const [selectedUser, setSelectedUser] = useState();
  const [selectedCar, setSelecetedCar] = useState();
  const [selectedProspectId, setSelectedProspectId] = useState();
  const [prestationPrice, setPrestationPrice] = useState("");
  const [certificationCost, setCertificationCost] = useState("");
  const [priceBeforeNegociation, setPriceBeforeNegociation] = useState("");

  useEffect(() => {
    setSearching(true);
    const timeoutId = setTimeout(() => init(), 1000);
    return () => {
      clearTimeout(timeoutId);
    };
  }, [isOpen, car, prospectId, onClose, search]);

  const init = () => {
    if (isOpen) {
      new Promise((resolve, reject) => {
        if (users.length > 0) return resolve(false);
        else
          userApi.getUsers().then((res) => {
            setUsers(res.data);
            return resolve(true);
          });
      }).then((isNew) => {
        if (isNew) setLoading(true);

        if (car) {
          setCertificationCost(car.malus);
          prospectApi
            .getProspects(10, 0, search, true)
            .then(({ data }) => {
              setProspects(data.filter((p) => p.enable));
              setLoading(false);
              setSearching(false);
            })
            .catch(() => {
              setLoading(false);
              setSearching(false);
            });
        } else if (prospectId) {
          carApi
            .getCars(10, 0, search)
            .then(({ data }) => {
              setCars(data);
              setLoading(false);
              setSearching(false);
            })
            .catch(() => {
              setLoading(false);
              setSearching(false);
            });
        }
      });
    }
  };

  const onChange = (e, v) => {
    if (!v) return;

    if (car) {
      setSelectedProspectId(v.id);
    } else if (prospectId) {
      setSelecetedCar(v);
      setCertificationCost(parseInt(v.malus));
      if (prestationPrice) {
        setPriceBeforeNegociation(
          parseInt(prestationPrice) +
            parseInt(v.malus) +
            parseInt(car ? car.price : selectedCar.price)
        );
      }
    }
  };

  const onSearch = (e) => {
    setSearch(e.target.value);
  };

  const onChangeSelectedUser = (e, v) => {
    setSelectedUser(v);
  };

  const handleGenerate = () => {
    if (car) {
      setLoading(true);
      pdfApi
        .postPdf(car.id, selectedProspectId, selectedUser, {
          prestationPrice: `${prestationPrice} €`,
          certificationCost: `${certificationCost} €`,
          priceBeforeNegociation: `${priceBeforeNegociation} €`,
        })
        .then(({ data }) => {
          window.open(
            `${
              process.env.NODE_ENV === "production"
                ? `https://${window.location.hostname}`
                : "http://localhost:7000"
            }/${data.url}`
          );
          if (onClose) onClose(true);
          setLoading(false);
        })
        .catch(() => {
          if (onClose) onClose();
          setLoading(false);
        });
    } else if (prospectId) {
      setLoading(true);
      pdfApi
        .postPdf(selectedCar.id, prospectId, selectedUser, {
          prestationPrice: `${prestationPrice} €`,
          certificationCost: `${certificationCost} €`,
          priceBeforeNegociation: `${priceBeforeNegociation} €`,
        })
        .then(({ data }) => {
          window.open(
            `${
              process.env.NODE_ENV === "production"
                ? `https://${window.location.hostname}`
                : "http://localhost:7000"
            }/${data.url}`
          );
          if (onClose) onClose(true);
          setLoading(false);
        })
        .catch(() => {
          if (onClose) onClose();
          setLoading(false);
        });
    } else {
      if (onClose) onClose();
    }
  };

  const handleClose = () => {
    if (onClose) onClose();
  };

  const onChangePrestationPrice = (e) => {
    setPrestationPrice(e.target.value);
    setPriceBeforeNegociation(
      parseInt(car ? car.malus : selectedCar.malus) +
        parseInt(e.target.value) +
        parseInt(car ? car.price : selectedCar.price)
    );
  };

  const onChangeCertificationCost = (e) => {
    setCertificationCost(e.target.value);
    if (prestationPrice) {
      setPriceBeforeNegociation(
        parseInt(prestationPrice) +
          parseInt(e.target.value) +
          parseInt(car ? car.price : selectedCar.price)
      );
    }
  };

  const onChangePriceBeforeNegociation = (e) =>
    setPriceBeforeNegociation(e.target.value);

  return cars || prospects ? (
    <Dialog
      open={isOpen}
      maxWidth="sm"
      fullWidth={true}
      onClose={handleClose}
      aria-labelledby="alert-dialog-title"
      aria-describedby="alert-dialog-description"
    >
      <DialogTitle id="alert-dialog-title">
        {!loading && "Génération du devis"}
        {loading && "Chargement..."}
      </DialogTitle>
      <DialogContent>
        {!loading ? (
          <form>
            <section className={classes.section}>
              <p>
                {prospectId && "Veuillez séléctionner une voiture"}
                {car && "Veuillez séléctionner un prospect"}
              </p>
              <Autocomplete
                required
                id="combo-box-demo"
                onChange={onChange}
                fullWidth={true}
                options={prospectId ? cars : car ? prospects : []}
                getOptionLabel={(option) =>
                  prospectId
                    ? `${option.brand} ${option.model} ${option.price}`
                    : car
                    ? `${option.lastname} ${option.firstname}`
                    : ""
                }
                renderInput={(params) => (
                  <TextField
                    {...params}
                    label={
                      prospectId
                        ? `${
                            searching ? "Rechercher de voiture en cours..." : "Voiture"
                          }`
                        : car
                        ? `${
                            searching ? "Rechercher de prospects en cours..." : "Prospects"
                          }`
                        : ""
                    }
                    variant="outlined"
                    onChange={onSearch}
                    placeholder={searching ? "Recherche en cours" : ""}
                  />
                )}
              />
            </section>
            <section className={classes.section}>
              <p>Veuillez sélectionner un commercial</p>
              <Autocomplete
                required
                id="combo-box-demo"
                onChange={onChangeSelectedUser}
                fullWidth={true}
                options={users}
                getOptionLabel={(user) => `${user.firstname} ${user.lastname}`}
                renderInput={(params) => (
                  <TextField
                    {...params}
                    label="Commercial"
                    variant="outlined"
                  />
                )}
              />
            </section>
            <section className={classes.section}>
              <p>Informations à compléter</p>
              <TextField
                required
                type="number"
                id="prestationPrice"
                name="prestationPrice"
                label="Prix de la prestation Jung & Werth (€)"
                fullWidth
                autoComplete="prestationPrice"
                value={prestationPrice}
                onChange={onChangePrestationPrice}
              />
              <TextField
                required
                type="number"
                id="certificationCost"
                name="certificationCost"
                label="Cout du certification d'immatriculation (€)"
                fullWidth
                autoComplete="certificationCost"
                value={certificationCost}
                onChange={onChangeCertificationCost}
              />
              <TextField
                required
                type="number"
                id="priceBeforeNegociation"
                name="priceBeforeNegociation"
                label="Prix avant la négociation (€)"
                fullWidth
                autoComplete="priceBeforeNegociation"
                value={priceBeforeNegociation}
                onChange={onChangePriceBeforeNegociation}
              />
            </section>
          </form>
        ) : (
          <LinearProgress />
        )}
      </DialogContent>
      <DialogActions>
        <Button
          onClick={handleGenerate}
          color="primary"
          autoFocus
          disabled={
            loading ||
            prestationPrice === "" ||
            certificationCost === "" ||
            priceBeforeNegociation === "" ||
            (car && !selectedProspectId) ||
            (!car && !selectedCar)
          }
        >
          Générer le devis
        </Button>
        <Button onClick={handleClose} color="secondary" disabled={loading}>
          Annuler
        </Button>
      </DialogActions>
    </Dialog>
  ) : (
    <></>
  );
}

GenerateDevis.propTypes = {
  isOpen: PropTypes.bool,
  car: PropTypes.string,
  prospectId: PropTypes.string,
  onClose: PropTypes.func,
};
