import axios from "axios";

let baseURL = process.env.NODE_ENV === "production" ?  `https://${window.location.hostname}` : "http://localhost:7000";

const getToken = () => JSON.parse(localStorage.getItem("user")) ? JSON.parse(localStorage.getItem("user")).accessToken : "";

const prospectApi = {
  getProspects: (page_size, page_number, search, enable) => {
    let params = [];
    if(page_size || page_size == 0) params.push(`page_size=${page_size}`);
    if(page_number || page_number == 0) params.push(`page_number=${page_number}`);
    if(search) params.push(`search=${search}`);
    params.push(`enable=${enable}`);

    let query = params.reduce((acc, param, index) => `${index == 0 ? `?${param}` : `${acc}&${param}`}`, "");

    return axios({
      method: "get",
      baseURL,
      url: `api/prospects${query}`,
      headers: { Authorization: `Bearer ${getToken()}` },
    });
  },

  getProspect: (id) => {
    return axios({
      method: "get",
      baseURL,
      url: `api/prospects/${id}`,
      headers: { Authorization: `Bearer ${getToken()}` },
    }); 
  },

  addProspect: (user) => {
    return axios({
      method: "post",
      baseURL,
      url: `api/prospects`,
      data: user,
      headers: { Authorization: `Bearer ${getToken()}` },
    });
  },

  updateProspect: (id, user) => {
    return axios({
      method: "put",
      baseURL,
      url: `api/prospects/${id}`,
      data: user,
      headers: { Authorization: `Bearer ${getToken()}` },
    });
  },

  removeProspect: (id) => {
    return axios({
      method: "delete",
      baseURL,
      url: `api/prospects/${id}`,
      headers: { Authorization: `Bearer ${getToken()}` },
    });
  },
};

export default prospectApi;
