import React, { useEffect, useState } from "react";
import { useHistory } from "react-router-dom";
import carApi from "../api/carApi";
import {
  Button,
  Checkbox,
  Chip,
  FormControl,
  FormControlLabel,
  FormGroup,
  FormHelperText,
  FormLabel,
  Grid,
  IconButton,
  LinearProgress,
  Paper,
  Switch,
  TableSortLabel,
  TextField,
  Toolbar,
  Tooltip,
  Typography,
} from "@material-ui/core";
import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import TableContainer from "@material-ui/core/TableContainer";
import TableHead from "@material-ui/core/TableHead";
import TableRow from "@material-ui/core/TableRow";
import Message from "../components/Message";
import { lighten, makeStyles } from "@material-ui/core/styles";
import ConfirmButton from "../components/ConfirmButton";
import DeleteIcon from "@material-ui/icons/Delete";
import DriveEtaIcon from "@material-ui/icons/DriveEta";
import AccountBalanceWalletIcon from "@material-ui/icons/AccountBalanceWallet";
import LocalOfferIcon from '@material-ui/icons/LocalOffer';
import clsx from "clsx";
import wordpressApi from "../api/wordpressApi";
import AccountCircleIcon from "@material-ui/icons/AccountCircle";
import Skeleton from "@material-ui/lab/Skeleton";
import { useCar } from "../contexts/carContext";
import EditIcon from "@material-ui/icons/Edit";
import DesktopAccessDisabledIcon from "@material-ui/icons/DesktopAccessDisabled";
import DesktopWindowsIcon from "@material-ui/icons/DesktopWindows";
import CloudOffIcon from "@material-ui/icons/CloudOff";
import CloudIcon from "@material-ui/icons/Cloud";

const useStyles = makeStyles((theme) => ({
  container: {
    marginBottom: "40px",
  },
  paper: {
    padding: theme.spacing(3),
    display: "flex",
    overflow: "auto",
    flexDirection: "column",
  },
  table: {
    minWidth: 650,
  },
  carActions: {
    display: "grid",
    gridTemplateColumns: "repeat(2, minmax(0, 1fr))",
    gap: "0.8rem",
    padding: "0.5rem",
  },
  carIcon: {
    maxHeight: "70px",
  },
  btnSearch: {
    display: "flex",
    alignItems: "flex-end",
  },
  toolbarRoot: {
    paddingLeft: theme.spacing(2),
    paddingRight: theme.spacing(1),
  },
  toolbarHighlight: {
    color: theme.palette.primary.main,
    backgroundColor: lighten(theme.palette.primary.light, 0.85),
  },
  toolbarTitle: {
    flex: "1 1 100%",
  },
  carTags: {
    display: "flex",
    gap: "5px",
    justifyContent: "center",
  },
  visuallyHidden: {
    border: 0,
    clip: "rect(0 0 0 0)",
    height: 1,
    margin: -1,
    overflow: "hidden",
    padding: 0,
    position: "absolute",
    top: 20,
    width: 1,
  },
  loadMore: {
    width: "100%",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    margin: "10px 0",
    position: "relative",
  },
  buttonProgress: {
    color: "blue",
    position: "absolute",
    top: "50%",
    left: "50%",
    marginTop: -12,
    marginLeft: -12,
  },
  filterForm: {
    display: "flex",
    flexDirection: "row",
    marginTop: "2rem",
    gap: "2rem",
  },
}));

// Affiche la liste des voitures sauvegardées
const Cars = () => {
  const history = useHistory();
  const classes = useStyles();
  const {
    isLoading,
    setIsLoading,
    getCars,
    loadMoreCars,
    message,
    setMessage,
    search,
    setSearch,
    searchCars,
    setSearchCars,
    carsPagingation,
    setCarsPagination,
    disableLoadMore,
    setDisableLoadMore,
    countCars,
    countLeboncoin,
    countWordpress,
    countCaroom,
    setCountCaroom,
    prevIndex,
    setPrevIndex,
    sortLeboncoin,
    setSortLeboncoin,
    sortWordpress,
    setSortWordpress,
    sortInvalid,
    setSortInvalid,
    sortCaroom,
    setSortCaroom,
  } = useCar();

  const [selectAll, setSelectAll] = useState(false);
  const [selectedCarNumber, setSelectedCarNumber] = useState(0);
  const [order, setOrder] = useState("asc");
  const [orderBy, setOrderBy] = useState("");

  useEffect(() => {
    if (prevIndex) {
      let el = document.getElementById(`car-${prevIndex}`);
      if (el) el.scrollIntoView();
    }

    setPrevIndex();
  }, [prevIndex]);

  // Supprime une voiture
  const removeCar = (id, index) => {
    setIsLoading(true);
    carApi
      .removeCar(id)
      .then(() => {
        setSearchCars((prev) => {
          prev.splice(index, 1);
          return prev;
        });
        setIsLoading(false);
        setMessage({
          type: "success",
          text: "Le véhicule a bien été supprimé",
        });
      })
      .catch(() => {
        setIsLoading(false);
        setMessage({
          type: "error",
          text: "Un problème est survenu lors de la suppression du véhicule",
        });
      });
  };

  // Met à jour la liste des voitures en fonction de mots clés dans la barre de recherche
  const onSearch = (e) => {
    setCarsPagination(0);
    setDisableLoadMore(false);
    setOrderBy("");
    setOrder("ask");
    setSortLeboncoin(null);
    setSearch(e.target.value);
  };

  // Sélectionne ou dé-sélectionne toutes les voitures
  const onChangeSelectAll = () => {
    if (selectAll) {
      setSearchCars((prev) => {
        prev = prev.map((car) => {
          car.selected = false;
          return car;
        });
        setSelectedCarNumber(0);
        return prev;
      });
    } else if (!selectAll) {
      setSearchCars((prev) => {
        prev = prev.map((car) => {
          car.selected = true;
          return car;
        });
        setSelectedCarNumber(prev.length);
        return prev;
      });
    }
    setSelectAll((prev) => !prev);
  };

  // Selectionne ou dé-sélecitonne la voiture
  const onChangeSelectCar = (id) => {
    setSearchCars((prev) => {
      prev = prev.map((car) => {
        if (car.id === id) {
          car.selected = !car.selected;
        }
        return car;
      });
      setSelectedCarNumber(
        prev.reduce((acc, car) => (car.selected ? acc + 1 : acc), 0)
      );
      return prev;
    });
  };

  // Supprime toutes les voitures sélectionnées
  const onRemoveSelectedCars = () => {
    setIsLoading(true);
    let selectedCars = searchCars.filter((car) => car.selected);
    Promise.allSettled(selectedCars.map((car) => carApi.removeCar(car.id)))
      .then((res) => {
        setMessage({
          type: "success",
          text: `${res.reduce(
            (acc, val) => (val.status === "fulfilled" ? acc + 1 : acc),
            0
          )}/${res.length} voiture(s) supprimée(s)`,
        });
        setSelectAll(false);
        setSelectedCarNumber(0);
        setIsLoading(false);
      })
      .catch(() => {
        setIsLoading(false);
        setMessage({
          type: "error",
          text: "Un problème est survenu lors de la suppression des voitures",
        });
      });
  };

  // Exporte toutes les voitures séléctionnées vers le site wordpress Jung Werth
  const onExportSelectedCarsToJungWerth = () => {
    setIsLoading(true);
    let selectedCars = searchCars.filter((car) => car.selected);
    Promise.allSettled(selectedCars.map((car) => wordpressApi.addPost(car.id)))
      .then((res) => {
        setMessage({
          type: "success",
          text: `${res.reduce(
            (acc, val) => (val.status === "fulfilled" ? acc + 1 : acc),
            0
          )}/${res.length} poste(s) créé(s) sur Jung Werth`,
        });
        setSelectAll(false);
        setSelectedCarNumber(0);
        setIsLoading(false);
        getCars(true);
      })
      .catch(() => {
        setIsLoading(false);
        setMessage({
          type: "error",
          text: "Un problème est survenu lors de la création des postes sur Jung Werth",
        });
        getCars(true);
      });
  };

  // Exporte toutes les voitures sélectionnées vers leboicon
  const onExportSelectedCarsToLeboncoin = () => {
    setIsLoading(true);
    let selectedCars = searchCars.filter((car) => car.selected);
    Promise.allSettled(
      selectedCars.map((car) =>
        carApi.updateCar(car.id, { exportXML: true })
      )
    )
      .then((res) => {
        setMessage({
          type: "success",
          text: `${res.reduce(
            (acc, val) => (val.status === "fulfilled" ? acc + 1 : acc),
            0
          )}/${res.length} annonces(s) activée(s) pour un futur envoie vers leboncoin`,
        });
        setSelectAll(false);
        setSelectedCarNumber(0);
        setIsLoading(false);
        getCars(true);
      })
      .catch(() => {
        setIsLoading(false);
        setMessage({
          type: "error",
          text: "Un problème est survenu lors de l'activation des annonces",
        });
        getCars(true);
      });
  };

  // Active toutes les voitures sélectionnées pour caroom
  const onExportSelectedCaroom = () => {

    setIsLoading(true);
    let selectedCars = searchCars.filter((car) => car.selected && !car.caroom);

    if(countCaroom + selectedCars.length > 150) {
      setIsLoading(false);
      setMessage({
        type: "error",
        text: "Vous avez dépassé le quota autorisé de 150 annonces sur caroom",
      });
      return;
    }

    Promise.allSettled(
      selectedCars.map((car) =>
        carApi.updateCar(car.id, { caroom: true })
      )
    )
      .then((res) => {
        let nbSuccessCaroom = res.reduce(
          (acc, val) => (val.status === "fulfilled" ? acc + 1 : acc),
          0
        )
        setMessage({
          type: "success",
          text: `${nbSuccessCaroom}/${res.length} annonces(s) activée(s) pour caroom`,
        });
        setCountCaroom(prev => prev + nbSuccessCaroom)
        setSelectAll(false);
        setSelectedCarNumber(0);
        setIsLoading(false);
        getCars(true);
      })
      .catch(() => {
        setIsLoading(false);
        setMessage({
          type: "error",
          text: "Un problème est survenu lors de l'activation des annonces",
        });
        getCars(true);
      });
  };

  // Trie la liste des voitures en fonction de la colonne sélectionnée
  const handleRequestSort = (property) => {
    const isAsc = orderBy === property && order === "asc";
    setOrder(isAsc ? "desc" : "asc");
    setOrderBy(property);

    if (property === "Leboncoin") {
      setSortLeboncoin(!isAsc);
    } else
      setSearchCars((prev) =>
        prev.sort((a, b) => {
          if (property === "Prix") {
            return parseInt(a.price.replace(/\D/g, "")) >
              parseInt(b.price.replace(/\D/g, ""))
              ? isAsc
                ? 1
                : -1
              : isAsc
              ? -1
              : 1;
          } else if (property === "Marque") {
            return a.brand.toLowerCase() < b.brand.toLowerCase()
              ? isAsc
                ? 1
                : -1
              : isAsc
              ? -1
              : 1;
          } else if (property === "Modèle") {
            return a.model.toLowerCase() < b.model.toLowerCase()
              ? isAsc
                ? 1
                : -1
              : isAsc
              ? -1
              : 1;
          } else if (property === "Prospect lié") {
            return a.prospects.length > b.prospects.length
              ? isAsc
                ? 1
                : -1
              : isAsc
              ? -1
              : 1;
          } else return 1;
        })
      );
  };

  const getNbInvalidityDays = (car) => {
    // To set two dates to two variables
    var date1 = new Date(car.invalidity);
    var date2 = new Date();

    // To calculate the time difference of two dates
    var Difference_In_Time = date2.getTime() - date1.getTime();

    // To calculate the no. of days between two dates
    return Math.round(Difference_In_Time / (1000 * 3600 * 24));
  };
  const getAnnonceDuration = (car) => {
    // To set two dates to two variables
    var date1 = new Date(car.createdAt);
    var date2 = new Date(car.invalidity);

    // To calculate the time difference of two dates
    var Difference_In_Time = date2.getTime() - date1.getTime();

    // To calculate the no. of days between two dates
    return Math.round(Difference_In_Time / (1000 * 3600 * 24));
  };

  const enableDisableLeboncoin = (car) => {
    setIsLoading(true);
    carApi
      .updateCar(car.id, { exportXML: !car.exportXML })
      .then((res) => {
        setMessage({
          type: "success",
          text: `Annonce ${car.exportXML ? "désactivée" : "activée"}`,
        });
        setIsLoading(false);
        getCars(true);
      })
      .catch(() => {
        setIsLoading(false);
        setMessage({
          type: "error",
          text: "Un problème est survenu",
        });
        getCars(true);
      });
  };

  const enableDisableJW = (car) => {
    setIsLoading(true);
    wordpressApi
      .disableEnablePost(car.id, !car.isDisable)
      .then((res) => {
        setMessage({
          type: "success",
          text: `Annonce ${car.isDisable ? "activée" : "désactivée"}`,
        });
        setIsLoading(false);
        getCars(true);
      })
      .catch(() => {
        setIsLoading(false);
        setMessage({
          type: "error",
          text: "Un problème est survenu",
        });
        getCars(true);
      });
  };

  return (
    <>
      <Grid container spacing={3} className={classes.container}>
        <Grid item xs={12}>
          <Paper className={classes.paper}>
            <p>
              Nombre total de véhicules: <strong>{countCars}</strong>, nombre
              total d'annonces sur leboncoin: <strong>{countLeboncoin}</strong>,
              nombre total d'annonces sur Jung&Werth:<strong>{countWordpress}</strong>, nombre total d'annonces sur caroom: <strong>{countCaroom}</strong>/150
              
            </p>
            <Grid container spacing={3}>
              <Grid item xs={12} md={12}>
                <TextField
                  required
                  id="search"
                  label="Recherche"
                  fullWidth
                  disabled={!searchCars}
                  autoComplete="search"
                  onChange={onSearch}
                />
              </Grid>
            </Grid>
            <FormControl component="fieldset" variant="standard">
              <FormGroup className={classes.filterForm}>
                <FormControlLabel
                  control={
                    <Switch
                      color="primary"
                      checked={sortLeboncoin}
                      onChange={(e) => setSortLeboncoin(e.target.checked)}
                      name="Leboncoin"
                    />
                  }
                  label="Leboncoin"
                />
                <FormControlLabel
                  control={
                    <Switch
                      color="primary"
                      checked={sortWordpress}
                      onChange={(e) => setSortWordpress(e.target.checked)}
                      name="Wordpress"
                    />
                  }
                  label="Jung&Werth"
                />
                <FormControlLabel
                  control={
                    <Switch
                      color="primary"
                      checked={sortInvalid}
                      onChange={(e) => setSortInvalid(e.target.checked)}
                      name="Invalid"
                    />
                  }
                  label="Invalide"
                />
                <FormControlLabel
                  control={
                    <Switch
                      color="primary"
                      checked={sortCaroom}
                      onChange={(e) => setSortCaroom(e.target.checked)}
                      name="Caroom"
                    />
                  }
                  label="Caroom"
                />
              </FormGroup>
            </FormControl>
          </Paper>
        </Grid>
      </Grid>
      <Grid container spacing={3} className={classes.container}>
        <Grid item xs={12}>
          <>
            {isLoading && <LinearProgress />}
            {selectedCarNumber > 0 && (
              <Toolbar
                className={clsx(classes.toolbarRoot, classes.toolbarHighlight)}
              >
                <Typography
                  className={classes.toolbarTitle}
                  color="inherit"
                  variant="subtitle1"
                  component="div"
                >
                  {selectedCarNumber} élément(s) selectionné(s)Nombre total de véhicu {"->"} 200
                  éléments maximum
                </Typography>

                <ConfirmButton
                  title="Êtes-vous certain de vouloir activer les fiches des voitures selectionnées pour caroom ?"
                  onConfirm={onExportSelectedCaroom}
                >
                  <Tooltip title="Activer les annonces pour caroom">
                    <IconButton aria-label="leboncoin">
                      <LocalOfferIcon />
                    </IconButton>
                  </Tooltip>
                </ConfirmButton>

                <ConfirmButton
                  title="Êtes-vous certain de vouloir activer les fiches des voitures selectionnées pour un futur envoie vers leboncoin ?"
                  content="Cette action sera irrévocable."
                  onConfirm={onExportSelectedCarsToLeboncoin}
                >
                  <Tooltip title="Activer les annonces pour un futur envoie vers Leboncoin">
                    <IconButton aria-label="leboncoin">
                      <AccountBalanceWalletIcon />
                    </IconButton>
                  </Tooltip>
                </ConfirmButton>

                <ConfirmButton
                  title="Êtes-vous certain de vouloir exporter les voitures selectionnées vers jung werth ?"
                  content="Cette action sera irrévocable."
                  onConfirm={onExportSelectedCarsToJungWerth}
                >
                  <Tooltip title="Exporter vers Jung Werth">
                    <IconButton aria-label="jungwerth">
                      <DriveEtaIcon />
                    </IconButton>
                  </Tooltip>
                </ConfirmButton>

                <ConfirmButton
                  title="Êtes-vous certain de vouloir supprimer les voitures selectionnées ?"
                  content="Cette action sera irrévocable."
                  onConfirm={onRemoveSelectedCars}
                >
                  <Tooltip title="Supprimer">
                    <IconButton aria-label="delete">
                      <DeleteIcon />
                    </IconButton>
                  </Tooltip>
                </ConfirmButton>
              </Toolbar>
            )}
            <TableContainer component={Paper}>
              <Table className={classes.table} aria-label="simple table">
                <TableHead>
                  <TableRow>
                    <TableCell align="center">
                      <Checkbox
                        checked={selectAll}
                        onChange={onChangeSelectAll}
                        color="primary"
                      />
                    </TableCell>
                    <TableCell align="center">Icône </TableCell>
                    <TableCell
                      align="center"
                      sortDirection={orderBy === "Prix" ? order : false}
                    >
                      <TableSortLabel
                        active={orderBy === "Prix"}
                        direction={orderBy === "Prix" ? order : "asc"}
                        onClick={() => handleRequestSort("Prix")}
                      >
                        {orderBy === "Prix" ? (
                          <span className={classes.visuallyHidden}>
                            {order === "desc"
                              ? "sorted descending"
                              : "sorted ascending"}
                          </span>
                        ) : null}
                        Prix
                      </TableSortLabel>
                    </TableCell>
                    <TableCell
                      align="center"
                      sortDirection={orderBy === "Marque" ? order : false}
                    >
                      <TableSortLabel
                        active={orderBy === "Marque"}
                        direction={orderBy === "Marque" ? order : "asc"}
                        onClick={() => handleRequestSort("Marque")}
                      >
                        {orderBy === "Marque" ? (
                          <span className={classes.visuallyHidden}>
                            {order === "desc"
                              ? "sorted descending"
                              : "sorted ascending"}
                          </span>
                        ) : null}
                        Marque
                      </TableSortLabel>
                    </TableCell>
                    <TableCell
                      align="center"
                      sortDirection={orderBy === "Modèle" ? order : false}
                    >
                      <TableSortLabel
                        active={orderBy === "Modèle"}
                        direction={orderBy === "Modèle" ? order : "asc"}
                        onClick={() => handleRequestSort("Modèle")}
                      >
                        {orderBy === "Modèle" ? (
                          <span className={classes.visuallyHidden}>
                            {order === "desc"
                              ? "sorted descending"
                              : "sorted ascending"}
                          </span>
                        ) : null}
                        Modèle
                      </TableSortLabel>
                    </TableCell>
                    <TableCell
                      align="center"
                      sortDirection={orderBy === "Prospect lié" ? order : false}
                    >
                      <TableSortLabel
                        active={orderBy === "Prospect lié"}
                        direction={orderBy === "Prospect lié" ? order : "asc"}
                        onClick={() => handleRequestSort("Prospect lié")}
                      >
                        {orderBy === "Prospect lié" ? (
                          <span className={classes.visuallyHidden}>
                            {order === "desc"
                              ? "sorted descending"
                              : "sorted ascending"}
                          </span>
                        ) : null}
                        Prospect lié
                      </TableSortLabel>
                    </TableCell>
                    <TableCell align="center">Tags</TableCell>
                    <TableCell align="center">Actions</TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {searchCars
                    ? searchCars.map((car, index) => (
                        <TableRow key={index} id={`car-${car.id}`}>
                          <TableCell align="center">
                            <Checkbox
                              checked={car.selected}
                              onChange={() => onChangeSelectCar(car.id)}
                              color="primary"
                            />
                          </TableCell>
                          <TableCell align="center">
                            <img
                              src={
                                car.carImages.length > 0
                                  ? car.carImages[0].url
                                  : ""
                              }
                              alt="voiture"
                              className={classes.carIcon}
                            />
                          </TableCell>
                          <TableCell align="center">{car.price}</TableCell>
                          <TableCell align="center">{car.brand}</TableCell>
                          <TableCell align="center">{car.model}</TableCell>
                          <TableCell align="center">
                            {Array.isArray(car.prospects) &&
                              car.prospects.length > 0 &&
                              car.prospects.map((prospect, index) => (
                                <Chip
                                  key={index}
                                  icon={<AccountCircleIcon />}
                                  label={`${prospect.firstname} ${prospect.lastname}`}
                                  clickable
                                  color="primary"
                                  onClick={() =>
                                    history.push(`/prospects/${prospect.id}`)
                                  }
                                />
                              ))}
                          </TableCell>
                          <TableCell align="center">
                            {car.wordpressUrl && (
                              <Chip
                                label={`Jung&Werth`}
                                color={car.isDisable ? "success" : "primary"}
                                style={{ margin: "0.3rem" }}
                              />
                            )}
                            {car.caroom && (
                              <Chip
                                label={`Caroom`}
                                color="primary"
                                style={{ margin: "0.3rem" }}
                              />
                            )}
                            {car.exportXML && (
                              <Chip
                                label={`Leboncoin`}
                                color="primary"
                                style={{ margin: "0.3rem" }}
                              />
                            )}
                            {car.invalidity && (
                              <>
                                <Chip
                                  label={`Invalide depuis ${getNbInvalidityDays(
                                    car
                                  )} jour(s)`}
                                  color="secondary"
                                  style={{ margin: "0.3rem" }}
                                />
                                <Chip
                                  label={`Valide durant ${getAnnonceDuration(
                                    car
                                  )} jour(s)`}
                                  color="secondary"
                                  style={{ margin: "0.3rem" }}
                                />
                              </>
                            )}
                          </TableCell>
                          <TableCell align="center">
                            <section className={classes.carActions}>
                              {car.wordpressUrl && (
                                <Tooltip
                                  title={
                                    car.isDisable
                                      ? "Activer l'annonce sur JW"
                                      : "Désactiver l'annonce sur JW"
                                  }
                                >
                                  <Button
                                    variant="contained"
                                    color="primary"
                                    onClick={() => enableDisableJW(car)}
                                  >
                                    {!car.isDisable ? (
                                      <DesktopAccessDisabledIcon />
                                    ) : (
                                      <DesktopWindowsIcon />
                                    )}
                                  </Button>
                                </Tooltip>
                              )}
                              <Tooltip
                                title={
                                  car.exportXML
                                    ? "Désactiver l'annonce sur Leboncoin"
                                    : "Activer l'annonce sur Leboncoin"
                                }
                              >
                                <Button
                                  variant="contained"
                                  color="primary"
                                  onClick={() => enableDisableLeboncoin(car)}
                                >
                                  {!car.exportXML ? (
                                    <CloudIcon />
                                  ) : (
                                    <CloudOffIcon />
                                  )}
                                </Button>
                              </Tooltip>
                              <Tooltip title="Editer">
                                <Button
                                  variant="contained"
                                  color="primary"
                                  onClick={() => {
                                    history.push(`/cars/${car.id}`);
                                    setPrevIndex(
                                      searchCars[
                                        index == 0 ? index + 1 : index - 1
                                      ].id
                                    );
                                  }}
                                >
                                  <EditIcon />
                                </Button>
                              </Tooltip>
                              <ConfirmButton
                                title="Êtes-vous certain de vouloir supprimer cette voiture ?"
                                content="Cette action sera irrévocable."
                                btnText={
                                  <Tooltip title="Supprimer">
                                    <DeleteIcon />
                                  </Tooltip>
                                }
                                onConfirm={() => removeCar(car.id, index)}
                              />
                            </section>
                          </TableCell>
                        </TableRow>
                      ))
                    : [1, 2, 3, 4, 5, 6, 7, 8, 9, 10].map((e, i) => (
                        <TableRow key={i}>
                          <TableCell>
                            <Skeleton
                              variant="rect"
                              height="48px"
                              width="100%"
                            />
                          </TableCell>
                          <TableCell>
                            <Skeleton
                              variant="rect"
                              height="48px"
                              width="100%"
                            />
                          </TableCell>
                          <TableCell>
                            <Skeleton
                              variant="rect"
                              height="48px"
                              width="100%"
                            />
                          </TableCell>
                          <TableCell>
                            <Skeleton
                              variant="rect"
                              height="48px"
                              width="100%"
                            />
                          </TableCell>
                          <TableCell>
                            <Skeleton
                              variant="rect"
                              height="48px"
                              width="100%"
                            />
                          </TableCell>
                          <TableCell>
                            <Skeleton
                              variant="rect"
                              height="48px"
                              width="100%"
                            />
                          </TableCell>
                          <TableCell>
                            <Skeleton
                              variant="rect"
                              height="48px"
                              width="100%"
                            />
                          </TableCell>
                          <TableCell>
                            <Skeleton
                              variant="rect"
                              height="48px"
                              width="100%"
                            />
                          </TableCell>
                          <TableCell>
                            <Skeleton
                              variant="rect"
                              height="48px"
                              width="100%"
                            />
                          </TableCell>
                        </TableRow>
                      ))}
                </TableBody>
              </Table>
            </TableContainer>
            {searchCars && searchCars.length > 0 && (
              <div className={classes.loadMore}>
                <Button
                  variant="contained"
                  color="primary"
                  disabled={isLoading || disableLoadMore}
                  onClick={loadMoreCars}
                >
                  Charger plus de voiture
                </Button>
              </div>
            )}
          </>
        </Grid>
        <Message
          text={message.text}
          type={message.type}
          onClose={() =>
            setMessage({
              type: "", // success, error, warning or info
              text: "",
            })
          }
        />
      </Grid>
    </>
  );
};

export default Cars;
