import React, { useEffect, useState } from "react";
import { useHistory } from "react-router-dom";
import userApi from "../api/userApi";
import { Button, Fab, Grid, Paper } from "@material-ui/core";
import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import TableContainer from "@material-ui/core/TableContainer";
import TableHead from "@material-ui/core/TableHead";
import TableRow from "@material-ui/core/TableRow";
import Message from "../components/Message";
import PersonAddIcon from "@material-ui/icons/PersonAdd";
import { makeStyles } from "@material-ui/core/styles";
import ConfirmButton from "../components/ConfirmButton";

const useStyles = makeStyles((theme) => ({
  container: {
    paddingTop: theme.spacing(4),
    paddingBottom: theme.spacing(4),
  },
  paper: {
    padding: theme.spacing(2),
    display: "flex",
    overflow: "auto",
    flexDirection: "column",
  },
  table: {
    minWidth: 650,
  },
  btnCreateUser: {
    display: "flex",
    alignItems: "flex-end",
  },
  userActions: {
    display: "flex",
    justifyContent: "center",
    gap: "5px",
  },
  addUser: {
    position: "fixed",
    bottom: "30px",
    right: "30px",
  },
}));

// Affiche tous les utilisateurs
const Users = () => {
  const classes = useStyles();
  const history = useHistory();
  const [users, setUsers] = useState();
  const [isLoading, setIsLoading] = useState(false);
  const [message, setMessage] = useState({
    type: "", // success, error, warning or info
    text: "",
  });

  useEffect(() => {
    getUsers();
  }, []);

  // Recupères tous les utilisateurs
  const getUsers = () => {
    setIsLoading(true);
    userApi
      .getUsers()
      .then((res) => {
        setUsers(res.data);
        setIsLoading(false);
      })
      .catch((err) => {
        setIsLoading(false);
        setMessage({
          type: "error",
          text: "Impossible de charger la liste des utilisateurs",
        });
      });
  };

  // Supprime un utilisateur en fonction d'un id
  const removeUser = (id) => {
    userApi
      .removeUser(id)
      .then(() => {
        setMessage({
          type: "success",
          text: "L'utilisateur a bien été supprimé",
        });
        getUsers();
      })
      .catch(() => {
        setIsLoading(false);
        setMessage({
          type: "error",
          text: "Un problème est survenu lors de la suppression de l'utilisateur",
        });
      });
  };

  // Active ou désactive un utilisateurs
  const updateUser = (id, isEnable) => {
    userApi
      .updateUser(id, { enable: isEnable })
      .then(() => {
        getUsers();
        setMessage({
          type: "success",
          text: "L'utilisateur a bien été modifié",
        });
      })
      .catch(() => {
        getUsers();
        setMessage({
          type: "error",
          text: "Un problème est survenu lors de la modification de l'utilisateur",
        });
      });
  };

  return (
    <Grid container spacing={3}>
      <Grid item xs={12}>
        <TableContainer component={Paper}>
          <Table className={classes.table} aria-label="simple table">
            <TableHead>
              <TableRow>
                <TableCell align="center">Nom</TableCell>
                <TableCell align="center">Prénom</TableCell>
                <TableCell align="center">Email</TableCell>
                <TableCell align="center">Fonction</TableCell>
                <TableCell align="center">Actions</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {users &&
                users.map((user) => (
                  <TableRow key={user.id}>
                    <TableCell align="center">{user.lastname}</TableCell>
                    <TableCell align="center">{user.firstname}</TableCell>
                    <TableCell align="center">{user.email}</TableCell>
                    <TableCell align="center">{user.function}</TableCell>
                    <TableCell align="center" className={classes.userActions}>
                      {user.enable ? (
                        <>
                          <Button
                            variant="contained"
                            color="primary"
                            onClick={() => updateUser(user.id, false)}
                          >
                            Désactiver
                          </Button>
                          <Button
                            variant="contained"
                            color="primary"
                            onClick={() => history.push(`/users/${user.id}`)}
                          >
                            Editer
                          </Button>
                        </>
                      ) : (
                        <>
                          <Button
                            variant="contained"
                            color="primary"
                            onClick={() => updateUser(user.id, true)}
                          >
                            Activer
                          </Button>
                          <ConfirmButton
                            title="Êtes-vous certain de vouloir supprimer cette utilisateur ?"
                            content="Cette action sera irrévocable."
                            btnText="Supprimer"
                            onConfirm={() => removeUser(user.id)}
                          />
                        </>
                      )}
                    </TableCell>
                  </TableRow>
                ))}
            </TableBody>
          </Table>
        </TableContainer>
      </Grid>
      <Fab
        color="primary"
        className={classes.addUser}
        size="large"
        onClick={() => history.push("/users/add")}
      >
        <PersonAddIcon />
      </Fab>
      <Message
        text={message.text}
        type={message.type}
        onClose={() =>
          setMessage({
            type: "", // success, error, warning or info
            text: "",
          })
        }
      />
    </Grid>
  );
};

export default Users;
