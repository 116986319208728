import axios from "axios";

let baseURL = process.env.NODE_ENV === "production" ?  `https://${window.location.hostname}` : "http://localhost:7000";
const getToken = () => JSON.parse(localStorage.getItem("user")) ? JSON.parse(localStorage.getItem("user")).accessToken : "";

const blacklistApi = {
  addBlacklist: (blacklist) => {
    return axios({
      method: "post",
      baseURL,
      url: `api/blacklist`,
      headers: { Authorization: `Bearer ${getToken()}` },
      data: blacklist,
    });
  },
  getBlacklist: (page_size = 10, page_number = 0) => {
    let query = `?page_size=${page_size}&page_number=${page_number}`;
    return axios({
      method: "get",
      baseURL,
      url: `api/blacklist${query}`,
      headers: { Authorization: `Bearer ${getToken()}` }
    });
  },
  updateBlacklist: (id, blacklist) => {
    return axios({
      method: "put",
      baseURL,
      url: `api/blacklist/${id}`,
      headers: { Authorization: `Bearer ${getToken()}` },
      data: blacklist
    });
  },
  removeBlacklist: (id) => {
    return axios({
      method: "delete",
      baseURL,
      url: `api/blacklist/${id}`,
      headers: { Authorization: `Bearer ${getToken()}` },
    });
  },
};

export default blacklistApi;