import { Switch, Route, Redirect } from "react-router-dom";
import { useAuth } from "./contexts/auth";
import Parent from "./Parent";
import Scrap from "./views/Scrap";
import Signin from "./views/Signin";
import Cars from "./views/Cars";
import Car from "./views/Car";
import Users from "./views/Users";
import User from "./views/User";
import Prospects from "./views/Prospects";
import Prospect from "./views/Prospect";
import Traduction from "./views/Traduction";
import AddCar from "./views/AddCar";

// Router front
function Rooter() {
  const { user, userLoading } = useAuth(); // get current user

  return userLoading ? (
    <p>Loading..</p>
  ) : user ? (
    <Switch>
      <Route exact path="/scrap">
        <Parent title="Scraper une annonce">
          <Scrap />
        </Parent>
      </Route>
      <Route exact path="/add/car">
        <Parent title="Ajouter une voiture">
          <AddCar />
        </Parent>
      </Route>
      <Route exact path="/cars/:id">
        <Parent title="Voiture">
          <Car />
        </Parent>
      </Route>
      <Route exact path="/cars">
        <Parent title="Mes voitures">
          <Cars />
        </Parent>
      </Route>
      <Route exact path="/prospects">
        <Parent title="Mes prospects">
          <Prospects />
        </Parent>
      </Route>
      <Route exact path="/prospects/add">
        <Parent title="Ajouter un prospect">
          <Prospect />
        </Parent>
      </Route>
      <Route exact path="/prospects/:id">
        <Parent title="Editer le prospect">
          <Prospect />
        </Parent>
      </Route>
      <Route exact path="/users">
        <Parent title="Utilisateurs">
          <Users />
        </Parent>
      </Route>
      <Route exact path="/users/add">
        <Parent title="Ajouter un utilisateyr">
          <User />
        </Parent>
      </Route>
      <Route exact path="/users/:id">
        <Parent title="Modifier l'utilisateur">
          <User />
        </Parent>
      </Route>
      <Route exact path="/traductions">
        <Parent title="Traduction">
          <Traduction />
        </Parent>
      </Route>
      <Route path="/">
        <Parent title="Mes voitures">
          <Cars />
        </Parent>
      </Route>
    </Switch>
  ) : (
    <Switch>
      <Route exact path="/signin">
        <Signin />
      </Route>
      <Route path="/">
        <Redirect to="/signin" />
      </Route>
    </Switch>
  );
}

export default Rooter;
