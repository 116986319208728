import React, { useEffect, useState } from "react";
import PropTypes from "prop-types";
import MuiAlert from "@material-ui/lab/Alert";
import { Snackbar } from "@material-ui/core";

function Alert(props) {
  return <MuiAlert elevation={6} variant="filled" {...props} />;
}

export default function Message(props) {
  const [isOpen, setIsOpen] = useState(false);

  useEffect(() => setIsOpen(true), [props]);

  const handleMessageClose = () => {
    setIsOpen(false);
    if(props.onClose) props.onClose();
  }

  return props.text && props.type && (
    <Snackbar
      open={isOpen}
      autoHideDuration={6000}
      onClose={handleMessageClose}
      anchorOrigin={{ vertical: "bottom", horizontal: "right" }}
    >
      <Alert onClose={handleMessageClose} severity={props.type}>
        {props.text}
      </Alert>
    </Snackbar>
  );
}

Message.propTypes = {
  message: PropTypes.string,
  type: PropTypes.string,
  onClose: PropTypes.func
};
