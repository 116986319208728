import React, { useState } from "react";
import PropTypes from "prop-types";
import Dialog from "@material-ui/core/Dialog";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import DialogContentText from "@material-ui/core/DialogContentText";
import DialogTitle from "@material-ui/core/DialogTitle";
import { Button } from "@material-ui/core";

export default function ConfirmButton(props) {
  const [isOpen, setIsOpen] = useState(false);

  const handleClick = () => setIsOpen(true);

  const handleConfirm = () => {
    setIsOpen(false);
    if (props.onConfirm) props.onConfirm();
  };

  const handleCancel = () => {
    setIsOpen(false);
    if (props.onCancel) props.onCancel();
  };

  return (
    <>
      {props.children && <div onClick={handleClick}>{props.children}</div>}
      {!props.children && props.btnText && (
        <Button
          variant="contained"
          color={props.btnColor ? props.btnColor : "primary"}
          classeName={props.btnStyle ? props.btnStyle : {}}
          onClick={handleClick}
        >
          {props.btnText}
        </Button>
      )}
      <Dialog
        open={isOpen}
        onClose={handleCancel}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle id="alert-dialog-title">{props.title}</DialogTitle>
        <DialogContent>
          <DialogContentText id="alert-dialog-description">
            {props.content}
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleConfirm} color="primary">
            Confirmer
          </Button>
          <Button onClick={handleCancel} color="primary" autoFocus>
            Annuler
          </Button>
        </DialogActions>
      </Dialog>
    </>
  );
}

ConfirmButton.propTypes = {
  title: PropTypes.string,
  content: PropTypes.string,
  onConfirm: PropTypes.func,
  onCancel: PropTypes.func,
};
