import React, { useEffect, useState } from "react";
import {
  Button,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  TableSortLabel,
  TextField,
  Grid,
} from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";
import Message from "../components/Message";
import TraductionContainer from "../components/TraductionContainer";
import BlacklistContainer from "../components/BlacklistContainer";

const useStyles = makeStyles((theme) => ({}));

// Affiche toutes les traductions
const Traduction = () => {

  const [message, setMessage] = useState({
    text: "",
    type: "sucess"
  });
  
  return (
    <>
      <Grid container spacing={2} elevation={6} xs={12} md={12}>
        <TraductionContainer setMessage={setMessage}/>
        <BlacklistContainer setMessage={setMessage}/>
      </Grid>
      <Message
        text={message.text}
        type={message.type}
        onClose={() =>
          setMessage({
            type: "", // success, error, warning or info
            text: "", // message to display
          })
        }
      />
    </>
  );
};


export default Traduction;
