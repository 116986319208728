import React, { useEffect, useState } from "react";
import { makeStyles } from "@material-ui/core/styles";
import { useHistory, useParams } from "react-router-dom";
import {
  Button,
  FormControl,
  Grid,
  InputLabel,
  MenuItem,
  Paper,
  Select,
  TextField,
} from "@material-ui/core";
import Message from "../components/Message";
import userApi from "../api/userApi";

const useStyles = makeStyles((theme) => ({
  container: {
    marginBottom: "40px",
  },
  submit: {
    margin: theme.spacing(3, 0, 2),
  },
  formControl: {
    marginTop: "16px",
    marginBottom: "8px",
    minWidth: 120,
  },
}));

// Affiche la fiche d'un utilisateur
const User = () => {
  const classes = useStyles();
  const history = useHistory();
  const [message, setMessage] = useState({
    type: "", // success, error, warning or info
    text: "",
  });
  let { id } = useParams();
  const [loading, setLoading] = useState(false);
  const [firstname, setFirstname] = useState();
  const [lastname, setLastname] = useState();
  const [email, setEmail] = useState();
  const [_function, setFunction] = useState();
  const [password, setPassword] = useState("");
  const [enable, setEnable] = useState();
  const [phone, setPhone] = useState();

  useEffect(() => {
    if (id) getUser(id);
  }, [id]);

  // Recupère les informations liés à un utilisateur
  const getUser = (id) => {
    setLoading(true);
    userApi
      .getUser(id)
      .then(({ data }) => {
        setFirstname(data.firstname);
        setLastname(data.lastname);
        setEmail(data.email);
        setFunction(data.function);
        setEnable(data.enable);
        setPhone(data.phone);
        setLoading(false);
      })
      .catch(() => {
        setLoading(false);
        setMessage({
          type: "error",
          text: "Echec lors de la mise à jour de l'utilisateur",
        });
      });
  };

  const onChangeFirstname = (e) => setFirstname(e.target.value);
  const onChangeLastname = (e) => setLastname(e.target.value);
  const onChangeEmail = (e) => setEmail(e.target.value);
  const onChangeFunction = (e) => setFunction(e.target.value);
  const onChangePassword = (e) => setPassword(e.target.value);
  const onChangeEnable = (e) => setEnable(e.target.value);
  const onChangePhone = (e) => setPhone(e.target.value);

  // Met à jour l'utilisateur ou ajoute un utilisateur
  const onSubmit = () => {
    // Si l'utilisateur existe déjà
    if (id) {
      userApi
        .updateUser(id, {
          firstname,
          lastname,
          email,
          function: _function,
          password,
          enable,
          phone,
        })
        .then(() =>
          setMessage({
            type: "success",
            text: "Mise à jour de l'utilisteur réussi",
          })
        )
        .catch(() =>
          setMessage({
            type: "error",
            text: "Echec lors de la mise à jour de l'utilisateur",
          })
        );
    }
    // Si l'utilisateur n'existe pas
    else {
      userApi
        .addUser({
          firstname,
          lastname,
          email,
          function: _function,
          password,
          phone,
        })
        .then(({ data: { id } }) => {
          history.push(`/users`);
        })
        .catch(() =>
          setMessage({
            type: "error",
            text: "Erreur lors de l'ajout de l'utilisateur",
          })
        );
    }
  };

  return (
    <>
      {loading ? (
        <p>Chargement...</p>
      ) : (
        <Grid
          container
          spacing={3}
          component={Paper}
          elevation={6}
          xs={12}
          md={6}
          square
          className={classes.container}
        >
          <Grid item xs={12} md={4}>
            <TextField
              variant="outlined"
              margin="normal"
              required
              fullWidth
              id="firstname"
              label="Prénom"
              name="firstname"
              autoComplete="firstname"
              autoFocus
              value={firstname}
              onChange={onChangeFirstname}
            />
          </Grid>
          <Grid item xs={12} md={4}>
            <TextField
              variant="outlined"
              margin="normal"
              required
              fullWidth
              id="lastname"
              label="Nom"
              name="lastname"
              autoComplete="lastname"
              autoFocus
              value={lastname}
              onChange={onChangeLastname}
            />
          </Grid>
          <Grid item xs={12} md={4}>
            <TextField
              variant="outlined"
              margin="normal"
              required
              fullWidth
              id="email"
              label="Email"
              name="email"
              autoComplete="email"
              autoFocus
              value={email}
              onChange={onChangeEmail}
            />
          </Grid>
          <Grid item xs={12} md={4}>
            <TextField
              variant="outlined"
              margin="normal"
              required
              fullWidth
              id="phone"
              label="Phone"
              name="phone"
              autoComplete="phone"
              autoFocus
              value={phone}
              onChange={onChangePhone}
            />
          </Grid>
          <Grid item xs={12} md={4}>
            <TextField
              variant="outlined"
              margin="normal"
              required
              fullWidth
              id="function"
              label="Fonction"
              name="function"
              autoComplete="function"
              autoFocus
              value={_function}
              onChange={onChangeFunction}
            />
          </Grid>
          <Grid item xs={12} md={4}>
            <TextField
              variant="outlined"
              margin="normal"
              required
              fullWidth
              id="password"
              label="Mot de passe"
              name="password"
              autoComplete="password"
              autoFocus
              value={password}
              onChange={onChangePassword}
            />
          </Grid>
          {id && (
            <Grid item xs={12} md={4}>
              <FormControl
                variant="outlined"
                className={classes.formControl}
                fullWidth={true}
              >
                <InputLabel id="user_status">Activer</InputLabel>
                <Select
                  labelId="enable"
                  id="enable"
                  value={enable}
                  onChange={onChangeEnable}
                  label="Activer"
                >
                  <MenuItem value={true}>Activer</MenuItem>
                  <MenuItem value={false}>Désactiver</MenuItem>
                </Select>
              </FormControl>
            </Grid>
          )}
          <Grid item xs={12} md={12}>
            <Button
              fullWidth
              variant="contained"
              color="primary"
              className={classes.submit}
              onClick={onSubmit}
            >
              {id ? "Enregistrer" : "Ajouter l'utilisateur"}
            </Button>
          </Grid>
        </Grid>
      )}
      <Message
        text={message.text}
        type={message.type}
        onClose={() =>
          setMessage({
            type: "", // success, error, warning or info
            text: "",
          })
        }
      />
    </>
  );
};

export default User;
