import axios from "axios";

let baseURL =
  process.env.NODE_ENV === "production"
    ? `https://${window.location.hostname}`
    : "http://localhost:7000";
const getToken = () =>
  JSON.parse(localStorage.getItem("user"))
    ? JSON.parse(localStorage.getItem("user")).accessToken
    : "";

const carApi = {
  addCar: (car) => {
    return axios({
      method: "post",
      baseURL,
      url: `api/cars`,
      headers: { Authorization: `Bearer ${getToken()}` },
      data: car,
    });
  },
  uploadImage: (id, blob, name) => {
    var formData = new FormData();
    formData.append("carImage", blob, name);
    return axios({
      method: "post",
      baseURL,
      url: `api/cars/${id}/images`,
      headers: {
        Authorization: `Bearer ${getToken()}`,
        "Content-Type": "multipart/form-data",
      },
      data: formData,
    });
  },
  removeImages: (id) => {
    return axios({
      method: "delete",
      baseURL,
      url: `api/cars/${id}/images`,
      headers: { Authorization: `Bearer ${getToken()}` },
    });
  },
  getCars: (page_size = 10, page_number = 0, search, sort_lbc, sort_wp, sort_invalidity, sort_caroom) => {
    let query = `?page_size=${page_size}&page_number=${page_number}`;
    if(sort_lbc != null) query += `&sort_lbc=${sort_lbc}`
    if(sort_wp != null) query += `&sort_wp=${sort_wp}`
    if(sort_invalidity != null) query += `&sort_invalidity=${sort_invalidity}`
    if(sort_caroom != null) query += `&sort_caroom=${sort_caroom}`
    if(search) query = query + `&search=${search}`;
    return axios({
      method: "get",
      baseURL,
      url: `api/cars${query}`,
      headers: { Authorization: `Bearer ${getToken()}` },
    });
  },
  getCar: (id) => {
    return axios({
      method: "get",
      baseURL,
      url: `api/cars/${id}`,
      headers: { Authorization: `Bearer ${getToken()}` },
    });
  },
  updateCar: (id, car) => {
    return axios({
      method: "put",
      baseURL,
      url: `api/cars/${id}`,
      headers: { Authorization: `Bearer ${getToken()}` },
      data: car,
    });
  },
  updateCarProspects: (id, prospects) => {
    return axios({
      method: "put",
      baseURL,
      url: `api/cars/${id}/prospects`,
      headers: { Authorization: `Bearer ${getToken()}` },
      data: { prospects },
    });
  },
  removeCar: (id) => {
    return axios({
      method: "delete",
      baseURL,
      url: `api/cars/${id}`,
      headers: { Authorization: `Bearer ${getToken()}` },
    });
  },
  exportXml: (id, value) => {
    return axios({
      method: "put",
      baseURL,
      url: `api/cars/export`,
      data: { id, value },
      headers: { Authorization: `Bearer ${getToken()}` },
    });
  },
  getCount: () => {
    return axios({
      method: "get",
      baseURL,
      url: `api/cars/comptes`,
      headers: { Authorization: `Bearer ${getToken()}` },
    });
  },
  exportCaroom: () => {
    return axios({
      method: "get",
      baseURL,
      url: `api/cars/caroom/csv`,
      headers: { Authorization: `Bearer ${getToken()}` },
    });
  },
};

export default carApi;
